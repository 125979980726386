import { Component, Input, OnInit } from '@angular/core';
import { Pedido, TourPedido } from 'src/app/models';
import { FirestoreService } from '../../services/firestore.service';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { CarritoService } from '../../services/carrito.service';
import { Subscription,  Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { Tour } from '../../models';
declare var Stripe: any;


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  @Input() tourPedido: TourPedido;

pedido: Pedido;
carritoSuscriber: Subscription;
total: number;
reserva: number;
resta : number;
reservaR: number;
fecha: any;
foto: string;
cantidad: number;
stripeStatus: string;
public textBoton = 'Confirmar';
pedidos: Pedido[] =[];
idd: string;
Estado : string;
uid= '';
reservaA: number;

  constructor(private firestore: FirestoreService,
              private AfSto: AngularFirestore,
              private firAuth: FirebaseauthService,
              private carritoS: CarritoService,
              private route: Router,
              private firebaseauth: FirebaseauthService,
              private activatedRoute: ActivatedRoute,
              private fireFun: AngularFireFunctions,

  ) {
    this.firebaseauth.stateAuth().subscribe(res =>{
      if (res !== null){
          this.uid = res.uid;
      }
     });

    if(this.uid !==null){
      this.loadPedido();
    }

  //  this.loadPedido();

    this.stripeStatus='';

  }

  ngOnInit(): void {
    this.initCarrito();
     this.stripeStatus = this.getStripeStatus();

  }

  async click(){
    try{
    const uid = await this.firAuth.getUid();
    const path = 'Usuarios/' + uid + '/pedidos/'
    const stripe = Stripe(environment.stripe.key);
    this.carritoSuscriber = this.carritoS.getCarrito().subscribe(res =>{
      const idPro = res.uid;
      // console.log(idPro);
     // console.log(res);
// descomentar luego

       this.firestore.createDoc(this.pedido, path, idPro);

         this.fireFun.httpsCallable("stripeCheckout22")({id: idPro})
         .subscribe(result =>{
         // console.log(result);

          stripe.redirectToCheckout({
            sessionId: result,
          });
        });


    });
   }catch(error){
    // console.log(error);
    this.carritoS.BackCarrito();
    this.carritoS.Badpay();
   }
  }


  loadPedido(){
    this.carritoSuscriber = this.carritoS.getCarrito().subscribe(res =>{
      this.reserva = res.precioTotal;
    //  console.log(res.precioTotal);
      this.pedido = res;

// sumar total mas reserva

      this.pedido.productos.forEach((doc)=>{
           this.tourPedido = doc;
         //  console.log(doc.cantidad);
         //  console.log(this.tourPedido);
           this.Estado = doc.producto.pago;
         //  console.log(this.Estado);
       //    console.log(doc.producto);
         //  console.log(doc.producto.IdTour);
          //  if(doc.producto.pago == 'Reserva'){
          //     console.log(this.reserva );

          //  }
        //  console.log(this.tourPedido);


      });

     // this.getTotal();
      this.getCantidad();
      this.getFecha();
    });
   }

   initCarrito(){
    this.pedido = {
      uid: '',
      cliente: null,
      productos:  [],
      precioTotal: null,
      precioReserva: null,
      estado: 'reserva',
      fecha: null,
      fechaRese: null,
      motivo: null,
      valoracion: null,
      pago: null,
    };

  }
   getTotal(){


    this.total = 0;
    this.pedido.productos.forEach( producto =>{
     this.total = (producto.producto.valorA * producto.cantidad) + this.total;
    //  console.log(this.total);
    //  console.log(producto.producto)
      // this.reservaA = Math.round(this.total * 0.15);
      // console.log(this.reservaA);
     // this.reserva = this.total;
    //  this.pagoReT = this.reserva + this.reservaA;


    });




// // precioreserva es la cantidad total de tours
//     this.total = 0;
//     console.log(this.pedido.precioReserva);
//     this.pedido.productos.forEach( producto =>{
//      this.total = (producto.producto.valorA * this.pedido.precioReserva) + this.total;
//      console.log(this.total);
//     //  if(producto.producto.pago === 'Reserva'){
//     //   console.log(this.total);
//     //   this.reserva = Math.round(this.total * 0.15);
//     //    console.log(this.reserva)
//     // //  this.reservaR = Math.round(this.total * 0.15);
//     //  }else if(producto.producto.pago === 'Total')
//     //  {
//     //  //  console.log(this.total);
//     //    this.reserva = this.total;
//     //    console.log(this.reserva);
//     //  }

//   //    if(producto.producto.pago !== null){}
//   //   //  console.log(producto.producto.pago);
//   //   //  console.log(this.total, this.reservaR);
//   //  this.resta = this.total - this.reservaR;
//   //  this.Estado = "Mix";
//   //  this.reserva = this.resta;
//  //  console.log(this.Estado, this.reserva);

//     });

  }


  getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
     // console.log(this.cantidad);
      return this.cantidad;
  });
}

getFecha(){
  this.fecha = null;
  this.pedido.productos.forEach(producto =>{
    this.fecha = producto.producto.fecha;
    // console.log(this.fecha);


  });

}

back(){
  this.carritoS.BackCarrito();

  this.route.navigate(['/carrito']);
}

getStripeStatus(): string{
  let action = this.activatedRoute.snapshot.queryParamMap.get('action');
  if (action && action == 'cancel' || action == 'success')
  return action;
  return '';

}


// async getLast(){
//   let startAt = null;
//   if(this.pedidos.length){
//     startAt = this.pedidos[ this.pedidos.length - 1].fecha;
//   }
//   const uid = await this.firAuth.getUid();
//   const path = 'Usuarios/' + uid + '/porpagar/'
//   this.firestore.getCollectionItem<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res =>{
//     if(res.length){

//       res.forEach(pedido =>{
//      this.pedidos = Object.values(pedido);
//      const id = pedido.uid;
//     // console.log(id);
//      this.firestore.getDoP<Pedido>(path, id, uid);

//      // this.carrito.idPed(id);

//        // const exist = this.pedidos.find( pedidoExist =>{
//        //   const rep = pedidoExist === pedido;
//        //   return(rep);
//        // });
//        // if(exist === undefined){

//        // }

//    });
//    };
//   })
// }



// async confirm(){
//   const uid = await this.firAuth.getUid();
//   const path = 'Usuarios/' + uid + '/reservasOk/'
// // console.log(this.pedido);


//  this.pedido.productos.forEach(res=>{
//   const idd = res.producto.id;
//  // console.log(res.cantidad);

//   const data =[{
//      nombre: this.pedido.cliente.nombre,
//      email : this.pedido.cliente.email,
//      uid: this.pedido.cliente.uid,
//      Estado  : res.producto.Estado,
//      cantidad : res.cantidad,
//      fecha : res.producto.fecha,
//      fotoT : res.producto.fotoT,
//      hotel : res.producto.hotel,
//      id : res.producto.id,
//      idA : res.producto.idA,
//      nombreA : res.producto.nombreA,
//      nombreT : res.producto.nombreT,
//      ubicacion : res.producto.ubicacion,
//      valorA : res.producto.valorA,
//      idCom: res.producto.idCom,


//   }]
//   data.forEach((obj)=>{
//    // console.log(obj);
//     this.firestore.createConfir(obj, path, idd);

//   })

//  // this.firestore.createConfir(data, path, id);


//  });

// //   const idT = id;
// //   console.log(idT);
// //   const nomCli = this.pedido.cliente.nombre;
// //   const email = this.pedido.cliente.email;

// //   this.pedido.productos.forEach(res=>{
// //      console.log(res.producto.Estado);
// //      this.idd = res.producto.id;
// //      console.log(this.idd, idT);

// // if(this.idd === idT){
// //   console.log(this.idd, idT);
// //    // this.textBoton = 'ok';
// // }
// //   });

// }


}