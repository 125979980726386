import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { from } from 'rxjs';
import { SwiperOptions } from 'swiper';

import SwiperCore, { Zoom } from'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Zoom])

@Component({
  selector: 'app-img-gal',
  templateUrl: './img-gal.component.html',
  styleUrls: ['./img-gal.component.scss'],
})
export class ImgGalComponent implements OnInit {
  @ViewChild('swiper') swiper: SwiperComponent;
  
  public foto: [];
  public nom: string;

sliderOps={
  zoom: true,
  slidesPerView: 1.1,
  centeredSlides: true,
  spaceBetween:10,
  // autoplay: {
  //   delay: 3000,
  //   disableOnInteraction: false
  // }
}
  // config: SwiperOptions = {
  //   zoom: false,
  //   slidesPerView: 1.5,
  //   centeredSlides: true,
  //   spaceBetween:20
  // };
  constructor(private navParams: NavParams,
    private modal: ModalController) { }

  ngOnInit() {
    this.foto = this.navParams.get('foto');
    this.nom = this.navParams.get('nom');
  }


  zoom(zoomIn){
    const zoom = this.swiper.swiperRef.zoom;
    zoomIn ? zoom.in() : zoom.out();

  }

  close(){
    this.modal.dismiss();
  }

}
