import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ComentariosComponent } from 'src/app/componentes/comentarios/comentarios.component';
import { Tour } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-clasificacionvista',
  templateUrl: './clasificacionvista.component.html',
  styleUrls: ['./clasificacionvista.component.scss'],
})
export class ClasificacionvistaComponent implements OnInit {
  @Input() tour: Tour;

  condition: number = 0;
  nuevoSuscriber: Subscription;
  list: any[] = new Array(5);
  tours: Tour[] = [];
  public nombre : string;
  private Id : string;
  public foto: string;
  private idA: string;
  star0 = 0;
  star1 = 0;
  star2 = 0;
  star3 = 0;
  star4 = 0;
  star =[];
  comentario ='';
  click = false;
  tor: Tour;
  usid: string;
  tuid : string;
  starid: string;


  constructor( private firebaseauthService: FirebaseauthService,
               private firestoreService: FirestoreService,
               public navparams: NavParams,
               private modal : ModalController) {
            // this.getTour();
               }

  ngOnInit() {
    this.nombre = this.navparams.get('nombre');
    this.Id = this.navparams.get('Id');
    this.idA = this.navparams.get('idA');
    this.foto = this.navparams.get('foto');
    this.firestoreService.getTour().subscribe(tour =>{
      this.tours = tour;
    });

    // this.firestoreService.getStar().subscribe(sta=>{
    //   this.star = sta;
    //   sta.forEach(doc=>{
    //     console.log(doc)
    //     this.starid = doc.id;
    //   });

    // });
  }


  async review(i) {

    var k : any;
    let encontrado = false;
  //  console.log(i, this.Id, this.idA)
    const idTour = this.Id;
    const idAgen = this.idA;

    // console.log(this.star3)
    // const btn = document.getElementById('star').title;
    // console.log(btn);
     this.condition = i + 1;
    // console.log(btS, this.condition);
    const path = 'agencia/' + idAgen + '/tour/' + idTour +'/star/';
     const pathh = 'agencia/' + idAgen + '/tour/';
     const uid = await this.firebaseauthService.getUid();
  //   console.log(path, uid)

     const data: star={
      autor: this.firebaseauthService.datosUsuario.nombre,
      id: uid,
      turId : idTour,

     };
     this.tours.forEach(to =>{
      this.tor = to

      })
    // console.log(this.tor.id);
   this.firestoreService.getCollection<star>(path).subscribe(res =>{
    // console.log(res)
    res.forEach(doc=>{
    // console.log(doc);
       this.usid = doc.id;
       this.tuid = doc.turId;

       if(this.tuid === undefined && this.usid === undefined) {
       // console.log(this.usid);
          if(this.condition === 1){
           // console.log(this.condition, '1');
             this.star0 = this.condition;
            // console.log(this.star0,'==','Estella 1', this.condition);
             this.tor.star0 = this.tor.star0 + this.star0 ;
             this.firestoreService.updateDoc({star0: this.tor.star0} , pathh, idTour);
             this.firestoreService.createDoc(data, path, uid);
             this.firestoreService.updateDoc({star:'1'},path, uid);
           }
           if(this.condition === 2){
            // console.log(this.condition, '2');
             this.star1 = this.condition - 1;
             this.tor.star1 = this.tor.star1 + this.star1 ;
           //   console.log(this.star1,'==', 'Estrella 2');
             this.firestoreService.updateDoc({star1: this.tor.star1} , pathh, idTour);
             this.firestoreService.createDoc(data, path, uid);
             this.firestoreService.updateDoc({star:'2'},path, uid);
           }
           if(this.condition === 3){
            // console.log(this.condition, '3');
             this.star2 = this.condition - 2;
             this.tor.star2 = this.tor.star2 + this.star2 ;
           //  console.log(this.star2,'==','Estrella 3');
             this.firestoreService.updateDoc({star2: this.tor.star2} , pathh, idTour);
             this.firestoreService.createDoc(data, path, uid);
             this.firestoreService.updateDoc({star:'3'},path, uid);
           }
           if(this.condition === 4){
            // console.log(this.condition, '4');
             this.star3 = this.condition - 3;
             this.tor.star3 = this.tor.star3 + this.star3 ;
           //  console.log(this.star3,'==','Estrella 4');
             this.firestoreService.updateDoc({star3: this.tor.star3} , pathh, idTour);
             this.firestoreService.createDoc(data, path, uid);
             this.firestoreService.updateDoc({star:'4'},path, uid );
           }
           if(this.condition === 5){
            // console.log(this.condition, '5');
             this.star4 = this.condition - 4;
             this.tor.star4 = this.tor.star4 + this.star4 ;
           //   console.log(this.star4,'==', 'Estella 5');
             this.firestoreService.updateDoc({star4: this.tor.star4} , pathh, idTour);
             this.firestoreService.createDoc(data, path, uid);
             this.firestoreService.updateDoc({star:'5'},path, uid);
           }
          }else if (this.usid === uid){

            window.alert('Solo puede clasificar una vez');
            return
          }


    });
    return
   });
 //  console.log(this.tor.id, this.tuid, this.usid, idTour);
  // if(idTour === this.tuid && this.usid === undefined && this.starid !== uid ){
    // console.log(this.usid, uid);


    //  this.nuevoSuscriber =  this.firestoreService.getCollectioon(path).subscribe(res=>{
    //      console.log(res);



    //    })
  }


  closeAg(){
    this.modal.dismiss()
  }



  comentar(){
    const comentario = this.comentario;
    const path = 'agencia/'+ this.idA+ '/tour/' + this.Id+ '/comentarios';
    const data: Comentario = {
           autor: this.firebaseauthService.datosUsuario.nombre,
           comentario,
           fecha: new Date(),
           id: this.firestoreService.getId(),
         }
         if(comentario.length){
          this.firestoreService.createDoc(data, path, data.id).then( ()=>{
            this.comentario = '';
          });
         }else{
          window.alert('Debe ingresar texto');
         }


      //  }else{
      //   this.router.navigate(['./tours-m']);
      //   return;
      //  }
   }


}

interface Comentario{
  autor: string;
  comentario: string;
  fecha: any;
  id: string;
}
interface star{
  autor: string;
  id: string;
  turId: string;
}


// const clasi = to.clasificacion;


