import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { InformacionComponent } from './informacion/informacion.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { AgenciasComponent } from './agencias/agencias.component';
import { PerfilComponent } from './perfil/perfil.component';
import { CarritoComponent } from './carrito/carrito.component';
import { MistoursComponent } from './mistours/mistours.component';
import { ResetpasswComponent } from './resetpassw/resetpassw.component';
import { ComponentesModule } from '../componentes/componentes.module';
import { TourComponent } from './tour/tour.component';
import { PipesModule } from '../pipes/pipes.module';
import { TerminosComponent } from './terminos/terminos.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { PerfilUsmComponent } from './perfil-usm/perfil-usm.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SuccessComponent } from './success/success.component';
import { CanceladosComponent } from './cancelados/cancelados.component';
import { MistourscanceladosComponent } from './mistourscancelados/mistourscancelados.component';
import { ExitotourscanceladoComponent } from './exitotourscancelado/exitotourscancelado.component';
import { CancelpagoComponent } from './cancelpago/cancelpago.component';
import { MisbeneficiosComponent } from './misbeneficios/misbeneficios.component';

const routes: Routes = [
  
];

@NgModule({
  declarations: [
    InformacionComponent,
    BeneficiosComponent,
    AgenciasComponent,
    PerfilComponent,
    CarritoComponent,
    MistoursComponent,
    ResetpasswComponent,
    TerminosComponent,
    SendEmailComponent,
    PerfilUsmComponent,
    CheckoutComponent,
    SuccessComponent,
    CanceladosComponent,
    MistourscanceladosComponent,
    ExitotourscanceladoComponent,
    CancelpagoComponent,
    MisbeneficiosComponent,
    TourComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ComponentesModule, 
    ReactiveFormsModule
  ]
})
export class PagesModule { }
