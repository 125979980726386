import { Component, Input, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { Pedido } from '../../models';
import { Subscription } from 'rxjs';
import { CarritoService } from 'src/app/services/carrito.service';
import { Router } from '@angular/router';
import {Tour, TourPedido } from 'src/app/models';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-cancelados',
  templateUrl: './cancelados.component.html',
  styleUrls: ['./cancelados.component.scss'],
})
export class CanceladosComponent implements OnInit {
  @Input() tourPedido: TourPedido;

  pedidosCancelados: Pedido[] = [];
  pedidos: any[];
  canceladoSuscriber: Subscription;
  uid = '';
  tours: Tour []=[];

  constructor(public firestoreService: FirestoreService,
              private carritoSer: CarritoService,
              private router: Router,
              public firebaseauthService: FirebaseauthService) { }

 async  ngOnInit() {
    this.getToursCancelados();

  }

  async getToursCancelados(){
    const uid = await this.firebaseauthService.getUid();
    const path = 'Usuarios/' + uid + '/cancelados/';


      this.firestoreService.getCollection<Tour>(path).subscribe(res=>{
        if(res.length){

       this.tours = res;
         // this.pedidos = res;


         // console.log(this.pedidosCancelados);
          // this.pedidosCancelados.forEach(tour=>{
          //     tour.productos.forEach(ress =>{
          //       console.log(ress);
          //       this.tourPedido = ress;
          //       this.tours = this.tourPedido.producto;

          //     })

         // })
        }



      //     res.forEach(pedido=>{
      //      // this.tour1 = pedido;

      //       // console.log(pedido.productos);
      //       // this.pedidosCancelados.push(pedido);

      //   //    this.pedidosCancelados.push(pedido);
      //  //  console.log(pedido.productos);

      //       // const exist = this.pedidos.find( pediExist => {
      //       //   const rea =  pediExist.uid === pedido.uid;
      //       //   console.log(rea);

      //       //   return(rea);

      //       // });
      //       // if( exist === undefined){
      //       // this.pedidosCancelados.push(pedido);
      //       // }
      //     });
        }
  );

  }



 async cancelar(tours){
  // console.log(tours);
  const uid = await this.firebaseauthService.getUid();
  // console.log(uid);
  // await this.firestoreService.updateCancel(uid, tours);
 // window.alert('Cancelado')
  this.router.navigate(['exitocancelado']);
  // const id =tours.producto.id;
  // // console.log('cancelar()=>', tours.motivo);
  // console.log(tours.producto.Estado);
  // tours.producto.update({
  // Estado :'cancelado'
  // })

  //   const path='Usuarios/' + uid + '/cancelados/';
  //   console.log(path);

  //   const updateDoc={
  //     Estado: 'cancelado',
  //     motivo: tours.motivo,
  //   };
    // if(tours.motivo.length){
    //  //  await  this.firestoreService.updateDoc(updateDoc, path, id).then(()=>{
    //   //  console.log('Actualizado');

    //     // this.carritoSer.clearCancel();
    //  // });
    // }

// const patht='Usuarios/' + uid + '/pedidos/';


 }


  // async cancelar(tours){
  //   console.log(tours.pedido.Estado);

  //   const uid = await this.firebaseauthService.getUid();
  //   const path = 'Usuarios/' + uid + '/cancelados/';


  //     this.firestoreService.getCollection<Pedido>(path).subscribe(res=>{
  //       if(res.length){
  //         console.log(res);
  //      res.forEach(doc=>{
  //         console.log(doc);


  //      })
  //       }
  //       }
  // );
  // }
}
