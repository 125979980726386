import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Beneficio } from 'src/app/models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Timestamp} from '@firebase/firestore';
import { prototype } from 'events';
@Component({
  selector: 'app-misbeneficios',
  templateUrl: './misbeneficios.component.html',
  styleUrls: ['./misbeneficios.component.scss'],
})
export class MisbeneficiosComponent implements OnInit {
bene: Beneficio[]=[];
idBene: Subscription;
nuevos: Subscription;
pass = true;
fecha: any;
date: Date = new Date();
ress: Date;
idB : string;
  constructor( public firestoreService: FirestoreService,
                private firebaseauthService: FirebaseauthService) {
                  this.getNuevos();
                }

  ngOnInit() {
   this.getNuevos();

  }

  changeSegment($event:any){
    const opc = $event.detail.value;
    if(opc === 'nuevos'){
      this.pass = true;
      this.getNuevos();
    }

    if(opc === 'caducado'){
      this.pass = false;
      this.getCadu();
    }
  }

 async getNuevos(){

    const uid = await this.firebaseauthService.getUid();
    const path = 'Usuarios/' + uid + '/misBeneficios/';

    this.firestoreService.getCollectionItemmm<Beneficio>(path, 'estado', '==', 'nuevo').subscribe(res=>{
    res.forEach((doc)=>{
// console.log(doc.data())
     this.idB  = doc.id;
    //  console.log(doc.data().fecha, doc.data().estado, doc.data().ffecha);
    //  console.log(this.idB,'===', doc.id);
     const nombre = doc.data().nombre;
     const foto = doc.data().foto;
     const fechaa = doc.data().fecha;
     const fecha = doc.data().ffecha;
     const NomEm = doc.data().NomEm;
   //  console.log(fecha)
     const fecha2 = new Date(fecha).toDateString();
     const fecha1 = new Date().toDateString();
  //   console.log(fecha1, '==', fecha2);
     const data: Beneficio = {
      nombre,
      fecha: fechaa,
      ffecha: fecha,
      foto,
      estado:'caducado',
       NomEm: NomEm
    }
   // console.log(new Date(fecha2).getTime(), new Date(fecha1).getTime())
    const f2 = new Date().getTime();

   // console.log(fecha,'===', f2);
    // console.log(fecha1,'==', fecha2, fecha,'==', f2);
    if(fecha <= f2 && this.idB === doc.id){
      this.firestoreService.updateDoc(data, path, this.idB);
    }else{
     // console.log('Bad');
    }

    });
   });
 this.idBene =  this.firestoreService.getCollectionQueryInfoOo<Beneficio>(path, 'estado', '==', 'nuevo').subscribe(res=>{

        if(res.length){
          this.bene = res;

        }
    });

  }

  async getCadu(){
    const uid = await this.firebaseauthService.getUid();

    const path = 'Usuarios/' + uid + '/misBeneficios/';

    this.idBene = this.firestoreService.getCollectionQueryInfoOo<Beneficio>(path, 'estado', '==', 'caducado').subscribe(res=>{
        if(res.length){
          this.bene = res;

        }
    });
  }



}