// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig :{
  apiKey: "AIzaSyCHeQY33LSJmnh2vh4gX_HVhfjb1WfIySE",
  authDomain: "gosvay-74cf2.firebaseapp.com",
  databaseURL: "https://gosvay-74cf2-default-rtdb.firebaseio.com",
  projectId: "gosvay-74cf2",
  storageBucket: "gosvay-74cf2.appspot.com",
  messagingSenderId: "633778178632",
  appId: "1:633778178632:web:e441d56ae26271330be67d",
  measurementId: "G-DVN6Q38BED"
},
stripe : {
  key : "pk_live_51JzqCyGDuvYl6cfNaVuGzAqnhrFYANoZ3g14xcS8JJoKgpmb1wZ4u3ARbTUfwS19d30aMcvFFEbcUUq6JH4xSP1W00nJu28fWI",
},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
