import { Component, OnInit, ViewChild } from '@angular/core';
import { Pedido, Tour } from 'src/app/models';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ModalController } from '@ionic/angular';
import { TourvistaComponent } from 'src/app/vista/tourvista/tourvista.component';
import { Subscription } from 'rxjs';
import { CarritoService } from 'src/app/services/carrito.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
//import {  map } from 'rxjs/operators';



@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;


  tours: Tour[] = [];
  textoBuscar= '';
  SantiagoSub: Subscription;
  pedido: Pedido;
  cantidad: number;
  carritoSuscriber: Subscription;
  uid='';

 
  constructor( public firestoreService: FirestoreService,
               public angularFirestore: AngularFirestore,
               private firebaseauth: FirebaseauthService,
               private carritoService: CarritoService,
               public modal: ModalController) {
                this.firebaseauth.stateAuth().subscribe(res =>{
                  if (res !== null){
                      this.uid = res.uid;    
                  }
                 });

  }
 
  ngOnInit() {
    this.firestoreService.getTour().subscribe(tour =>{

      this.tours = tour;
        
    });
       this.getCiudad();
       this.loadPedido();
  }
 

  openTour(tour){
    this.modal.create({
      component: TourvistaComponent,
      componentProps :{
        nombre: tour.nombreT,
        descripcion: tour.descripcionT,
        valor: tour.valotT,
        foto: tour.fotoT,
        nombreA: tour.nombreA
      }
    }).then ((modal)=> modal.present())
    }

    openIn(tour){
      this.modal.create({
        component: TourvistaComponent,
        componentProps :{
          nombre: tour.nombreT,
          descripcion: tour.description,
          valor: tour.valotT,
          foto: tour.fotoT,
          nombreA: tour.nombreA
        }
      }).then ((modal)=> modal.present())
      
    }

    openPo(tour){
      this.modal.create({
        component: TourvistaComponent,
        componentProps :{
          nombre: tour.nombreT,
          descripcion: tour.descricao,
          valor: tour.valotT,
          foto: tour.fotoT,
          nombreA: tour.nombreA
        }
      }).then ((modal)=> modal.present())

    }




    buscarT(event:any){

      const texto = event.target.value;
       this.textoBuscar = texto ;
  
  }
 
  changeSegment(event:any){
    const opc = event.detail.value;
    if(opc === 'ciudad'){
      this.getCiudad();
    }
    if(opc === 'montaña'){
      this.getMontana();
    }
    if(opc === 'viñedos'){
      this.getVinedos();
    }
    if(opc === 'playas'){
      this.getPaya();
    }
    if(opc === 'sur'){
      this.getSur();
    }
    if(opc === 'norte'){
      this.getNorte();
    }
    if(opc === 'cultura'){
      this.getCultura();
    }
    if(opc === 'aventura'){
      this.getAventura();
    }
    if(opc === 'otros'){
      this.getOtros();
    }
  }
  getCiudad(){
    this.SantiagoSub = this.firestoreService.getTgroup( 'categoria', '==', 'Ciudad').subscribe(tour=>{
        if(tour.length){
          this.tours = tour;
        }
        
    });
  }

  getMontana(){
    this.SantiagoSub = this.firestoreService.getTgroup( 'categoria', '==', 'Montaña').subscribe(res=>{
        if(res.length){
          this.tours = res;          
        }
    });
  }
  
  getVinedos(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Viñedos').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }  

  getPaya(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Playas').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }
  
  getSur(){
    this.SantiagoSub = this.firestoreService.getTgroup( 'categoria', '==', 'Sur').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  
  }
  
  getNorte(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Norte').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }
  
  getCultura(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Cultural').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }
  
  getAventura(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Aventura').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }
  
  getOtros(){
    this.SantiagoSub = this.firestoreService.getTgroup('categoria', '==', 'Otros').subscribe(res=>{
        if(res.length){
          this.tours = res;
        }
    });
  }

  loadPedido(){
    this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
      this.pedido = res;      
      this.getCantidad(); 
           
    });
   }

   getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;      
  });
}
loadData(event, direction: 'top' | 'bottom') {
  setTimeout(()=>{
    event.target.complete();
    if(direction == 'top' && this.tours.length === 1000){
      event.target.disabled = true;
    }else if(direction == 'bottom' && this.tours.length === 1000){
      event.target.disabled = true;
    }
  }, 1000);
}


toggleInfiniteScroll() {
  this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
}

}

