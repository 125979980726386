import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Agencia, Tour, Usuario } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss'],
})
export class ComentariosComponent implements OnInit, OnDestroy {

  @Input() tour: Tour;
  agencia: Agencia;

  comentario ='';
  comentarios:Comentario[] = [];
  suscriber: Subscription;
  suscribeUserInfo: Subscription;
  uid = '';
  star0 = 0;
  star1 = 0;
  star2 = 0;
  star3 = 0;
  star4 = 0;
  total = 0;
  basado = 0;
  constructor(public modalController: ModalController,
                      public firestoreService: FirestoreService,
                      public firebaseauthService: FirebaseauthService,
                      public router: Router) {
                        this.firebaseauthService.stateAuth().subscribe(res =>{
                           if (res !== null) {
                             this.uid = res.uid;
                             //this.loadCliente();
                            }
                         });

                      }

  ngOnInit() {
    this.star0 = this.tour.star0;
    this.star1 = this.tour.star1;
    this.star2 = this.tour.star2;
    this.star3 = this.tour.star3;
    this.star4 = this.tour.star4;
    this.total = (this.star0 + this.star1 + this.star2 + this.star3 + this.star4)/5;
    this.basado = this.star0 + this.star1 + this.star2 + this.star3 + this.star4
    this.loadComentarios();
  }

ngOnDestroy(): void{
  if(this.suscriber){
    this.suscriber.unsubscribe();
  }
}


  closeModal(){
    this.modalController.dismiss();
  }



  loadComentarios(){
  //  console.log(this.tour.id)
    let startAt = null;
    if(this.comentarios.length){
      startAt = this.comentarios[ this.comentarios.length - 1].fecha;
    }
   const path = 'agencia/'+ this.tour.idCom + '/tour/' + this.tour.id + '/comentarios';
   this.suscriber =  this.firestoreService.getCollectionPag<Comentario>(path, 2, startAt).subscribe( res =>{
       if(res.length) {
         res.forEach( comentario => {
           const exist = this.comentarios.find( commentExist => {
              const dos =  commentExist.id === comentario.id;
              return(dos);
           });

           if( exist === undefined){
            this.comentarios.push(comentario);
           }
         });
           //this.comentarios = res;

       }
   });
  }



   comentar(){
      const comentario = this.comentario;
      const path = 'agencia/'+ this.tour.idCom + '/tour/' + this.tour.id + '/comentarios';
      const data: Comentario = {
             autor: this.firebaseauthService.datosUsuario.nombre,
             comentario,
             fecha: new Date(),
             id: this.firestoreService.getId(),
           }

           if(comentario.length){
            this.firestoreService.createDoc(data, path, data.id).then( ()=>{
              this.comentario = '';
            });
           }else{
            // console.log('Error');
           }


        //  }else{
        //   this.router.navigate(['./tours-m']);
        //   return;
        //  }
     }



 }


interface Comentario{
  autor: string;
  comentario: string;
  fecha: any;
  id: string;
}
