import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor( private http: HttpClient ) { }



//   charge(cantidad, tokenId){

//     // const firebaseURL = 'https://us-central1-gosvay-74cf2.cloudfunctions.net/PayWithStripe' ;

//     return this.http.post('https://us-central1-gosvay-74cf2.cloudfunctions.net/PayWithStripe',{
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//       stripeToken: tokenId,
//       cantidad: cantidad,
//       }),
//     }).subscribe(data =>{
//       console.log(data);

//   { 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' }  });
// }

charge(cantidad, tokenId){
// console.log(cantidad, tokenId);
  const   firebaseURL = 'http://127.0.0.1:4000/functions/stripeCheckout';
  //'http://localhost:5001/gosvay-74cf2/us-central1/stripeCheckout';

  return this.http.post(firebaseURL,{
    stripeToken: tokenId,
    cantidad: cantidad
  }).subscribe(data =>{
  });
}

}
