import { Component, OnInit } from '@angular/core';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../services/firestore.service';
import { Usuario } from '../../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {

  public user$: Observable<any>= this.FsAuth.auth.user; 

  constructor(private FsAuth: FirebaseauthService,
              private router: Router) { }

  ngOnInit() {
    // window.location.reload();
  }

  ReSendEmail(){
    this.FsAuth.sendVerificationEmail();
    
  }
  login(){
    this.router.navigate(['/perfil']).then(()=>{
      window.location.reload();
    });
  }

  perfil
}
