import {  AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { async } from '@firebase/util';
import { from, Subscription } from 'rxjs';
import { Pedido } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StripeService } from 'src/app/services/stripe.service';
// import { environment } from 'src/environments/environment';
// import {Functions} from '@angular/fire/functions';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
 //declare var Stripe: any;

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit, OnDestroy {
  // @ViewChild('cardInfo') cardInfo: ElementRef
  cardError: string;
  card: any;
  stripeStatus: string;
 // stripe = Stripe(environment.stripe.key);

  pedido: Pedido;
  carritoSuscriber: Subscription;
  total: number;
  reserva: number;
  reservaA: number;
  cantidad: number;
  fecha: string;// Date;
  id: string;
  hotel: string;
  item: Subscription;
  uid='';
  estado: string;
  wz: number;
  pagoReT : number;
  reservaR: number;
  resta: number;
  cantidadT: number;
  cantidadR: number;
  reserE: string;
  totalE: string;

  constructor(public firestoreService: FirestoreService,
              public firebaseautService: FirebaseauthService,
              private ngZone: NgZone,
              private stripeS: StripeService,
              private router: Router,
              private fireFun: AngularFireFunctions,
              private activatedRoute: ActivatedRoute,
              private firebaseauth: FirebaseauthService,
              public carritoService: CarritoService) {

                this.firebaseauth.stateAuth().subscribe(res =>{
                  if (res !== null){
                      this.uid = res.uid;
                  }
                 });

                if(this.uid !==null){
                   this.initCarrito();
                  this.loadPedido();
                }

            this.card = null;
            this.stripeStatus='';


   }

  //  ngAfterViewInit(){
  //   let elements = this.stripe.elements();

  //   var style = {
  //     base: {
  //       color: '#32325d',
  //       lineHeight: '24px',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '16px',
  //       '::placeholder': {
  //         color: '#aab7c4'
  //       }
  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a'
  //     }
  //   };
  //   this.card = elements.create('card',{ style: style });
  //   this.card.mount(this.cardInfo.nativeElement);
  //   this.card.addEventListener('change', this.onChange.bind(this));
  //  }

  //  onChange({ error }){
  //   if(error){
  //     this.ngZone.run(()=> this.cardError = error.message);
  //   }else{
  //     this.ngZone.run(()=> this.cardError = null);
  //   }

  // }

  ngOnInit(){

  }

  ngOnDestroy(){
    if(this.carritoSuscriber){
      this.carritoSuscriber.unsubscribe();
    }
  }
  // cambio(event){
  //    console.log(event.detail.value);


  //  }

  loadPedido(){
   this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
     this.pedido = res;
     this.getTotal();
     this.getCantidad();
     this.getFecha();
   });
  }

  initCarrito(){
    this.pedido = {
      uid: '',
      cliente: null,
      productos:  [],
      precioTotal: null,
      estado: 'reserva',
      fecha: null,
      precioReserva:null,
      fechaRese: null,
      motivo: null,
      valoracion: null,
      pago: null,
    };

  }

  getTotal(){
    this.total = 0;
    this.pedido.productos.forEach( producto =>{
     this.total = (producto.producto.valorA * producto.cantidad) + this.total;
    //  console.log(producto.producto)
      this.reservaA = Math.round(this.total * 0.15);

      this.reserva = this.total;
    //  this.pagoReT = this.reserva + this.reservaA;


    });

  }


  getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
    return this.cantidad;

  });
}
getFecha(){
  this.fecha = null;
  this.pedido.productos.forEach( producto =>{
    this.fecha = producto.producto.fecha;
    return this.fecha;
  });

}

// async onClick() {
//   if(!this.pedido.productos.length){
//     return;
//   }
//   this.pedido.productos.forEach( producto =>{
//     this.id = producto.producto.id;
//     this.hotel = producto.producto.hotel;
//   })

//   if(this.hotel.length){
//   this.pedido.fecha = new Date();
//   this.pedido.fechaRese = this.pedido.fechaRese;
//   this.pedido.precioTotal = (this.total)* 0.85; //Faltante x Pagar
//   this.pedido.uid = this.firestoreService.getId();
//   const idProducto = this.pedido.uid;


//   const uid = await this.firebaseautService.getUid();
//   const path = 'Usuarios/' + uid + '/pedidos/'


//   const cantidad = (this.total)* 0.15;

//   const { token, error} = await this.stripe.createToken(this.card);


//   if(token!==null){
//    const response = await this.stripeS.charge(cantidad, token.id);
//    console.log(response);

//    this.router.navigate(['/mis-tours']);

//   }else{
//     this.ngZone.run(()=> this.cardError = error.message);
//     console.log(this.cardError);

//   }
//   this.firestoreService.createDoc(this.pedido, path, this.pedido.uid).then(()=>{
//     this.carritoService.clearCarrito();
// })
//  }else(error)=>{
//  }
// }

async SaveItem(event) {
 // console.log(this.reservaA);
  try{
  if(!this.pedido.productos.length){
    return;
  }
  // this.firestoreService.updateDoc()
  this.pedido.pago = event;
  this.pedido.productos.forEach( producto =>{
  //  console.log(producto);
  //  console.log(producto.producto);
    this.id = producto.producto.id;
    this.hotel = producto.producto.hotel;
    producto.producto.pago = event;
    this.estado = event;
    this.wz = producto.producto.whatsapp;
    this.fecha = producto.producto.fecha;

  })
  // if(this.estado == undefined){
  //   console.log(this.estado);
  //   window.alert('Debe ingresar Opcion de Pago');
  // }
  if(this.hotel == undefined){
    window.alert('Debe Ingresar Hotel');
  }
  if(this.wz == undefined){
    window.alert('Debe ingresar WhatsApp')
  }
  if(this.fecha == undefined){
    window.alert('Debe Ingresar Fecha');
  }
  if(this.hotel !== undefined && this.estado !== undefined && this.wz !== undefined && this.fecha !== undefined){
   // console.log(this.hotel.length);
    this.pedido.fecha = new Date();
  // console.log(this.pedido.fecha);
   //Faltante x Pagar * 0.15

  this.pedido.uid = this.firestoreService.getId();
  const idProducto = this.pedido.uid;


  const uid = await this.firebaseautService.getUid();
  const path = 'Usuarios/' + uid + '/pedidos/'


 // const cantidad = (this.total)* 0.15;
  // console.log(cantidad);
this.pedido.productos.forEach(doc=>{
  if(this.estado === 'Reserva'){
    //this.reserE = this.estado;
   // console.log(doc.producto.cantidad);
    // this.cantidadR = doc.cantidad;
    // console.log(this.cantidad);
    this.pedido.precioTotal = this.reservaA; //Math.round((this.cantidad * doc.producto.valorA) * 0.15);
    this.pedido.precioReserva = this.cantidad;
   // console.log(this.pedido.precioTotal)
  }else if(this.estado === 'Total'){
    //  this.totalE = this.estado;

   // this.cantidadT = doc.cantidad;
    this.pedido.precioTotal = this.total;
    this.pedido.precioReserva = this.cantidad;
   // this.reservaA = ( doc.cantidad * doc.producto.valorA);
  //  console.log(this.pedido.precioTotal)
  }










//   if(doc.producto.pago !== null){
//     console.log(doc.producto.pago);
//    console.log(this.cantidadR, this.cantidadT);
// this.resta = this.reservaA + this.pedido.precioReserva;
// // this.Estado = "Mix";
// this.pedido.precioTotal= this.resta;
// console.log(this.pedido.precioTotal);
//  }

});
  this.firestoreService.createDoc(this.pedido, path, this.pedido.uid).then(()=>{
    // this.carritoService.clearCarrito();
  this.router.navigate(['/check-out']);
}).catch((error)=>{
 // console.log(error);

});


}
}catch(error){
   // window.alert('Debe Ingresar Nombre De Hotel ');

}
}
}

