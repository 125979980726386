import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InfoT, Pedido } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss'],
})
export class InformacionComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  private path = 'informacion/'

  info: InfoT [] = [];
  SantiagoSub: Subscription;
  // santiago: boolean = true;
  pedido: Pedido;
  cantidad: number;
  carritoSuscriber: Subscription;
  uid = '';



  constructor(public firestoreService: FirestoreService,
             private firebaseauth: FirebaseauthService,
             private carritoService: CarritoService,
             public modal: ModalController) { 
              this.firebaseauth.stateAuth().subscribe(res =>{
                if (res !== null){
                    this.uid = res.uid;    
                }
               });
  }

  ngOnInit(){
    this.getCiudad();
    this.loadPedido();
  }
 
  loadInfo(){
    this.firestoreService.getCollection<InfoT>(this.path).subscribe(res =>{
      this.info = res;
    })  
  }

  changeSegment(event:any){

    
    const opc = event.detail.value;
    if(opc === 'ciudad'){
      this.getCiudad();
    }
    if(opc === 'montaña'){
      this.getMontana();
    }
    if(opc === 'viñedos'){
      this.getVinedos();
    }
    if(opc === 'playas'){
      this.getPaya();
    }
    if(opc === 'sur'){
      this.getSur();
    }
    if(opc === 'norte'){
      this.getNorte();
    }
    if(opc === 'cultura'){
      this.getCultura();
    }
    if(opc === 'aventura'){
      this.getAventura();
    }
    if(opc === 'museos'){
      this.getMuseos();
    }
    if(opc === 'otros'){
      this.getOtros();
    }
  }



getMontana(){
  const path = 'informacion';

  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Montaña').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getVinedos(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Viñedos').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}


getCiudad(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Ciudad').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getPaya(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Playas').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getSur(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Sur').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });

}

getNorte(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Norte').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getCultura(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Cultural').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getAventura(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Aventura').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

getMuseos(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Museos').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}


getOtros(){
  const path = 'informacion';
  
  this.SantiagoSub = this.firestoreService.getCollectionQueryInfo<InfoT>(path, 'categoria', '==', 'Otros').subscribe(res=>{
      if(res.length){
        this.info = res;
      }
  });
}

loadPedido(){
  this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
    this.pedido = res;
    this.getCantidad(); 
         
  });
 }

 getCantidad(){
  this.cantidad = 0;
  this.pedido.productos.forEach( producto =>{
    this.cantidad = producto.cantidad + this.cantidad;
});
}

loadData(event, direction: 'top' | 'bottom') {
  setTimeout(()=>{
    event.target.complete();
    if(direction == 'top' && this.info.length === 1000){
      event.target.disabled = true;
    }else if(direction == 'bottom' && this.info.length === 1000){
      event.target.disabled = true;
    }
  }, 1000);
}

toggleInfiniteScroll() {
  this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
}


}
