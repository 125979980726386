import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
//import { auth } from 'firebase/app';
import * as firebase from 'firebase/compat/app';
import { Usuario } from '../models';
import { FirestoreService } from './firestore.service';


@Injectable({
  providedIn: 'root'
})
export class FirebaseauthService {
  datosUsuario: Usuario;
  userData: any;

 // authState = new BehaviorSubject(false);


  constructor(public auth: AngularFireAuth,
             private afs:AngularFirestore,
             private firestoreService: FirestoreService) {
               this.auth.authState.subscribe((user)=>{
                if(user){
                  this.userData = user;
                  localStorage.setItem('user', JSON.stringify(this.userData));
                  JSON.parse(localStorage.getItem('user'));
                  return this.afs.doc<Usuario>(`Usuarios/${user.uid}`).valueChanges();
                }else{
                  localStorage.setItem('user',null);
                  JSON.parse(localStorage.getItem('user'));
                }
               });
    this.stateUser();
    this.getUid();
  }

stateUser(){
  this.stateAuth().subscribe(res =>{
     if (res !== null) {
      this.getInfoUser();
   }
   });
}


  login(email: string, password: string){
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout(){
    return this.auth.signOut();
  }

  registrar(email: string, password: string){
    return this.auth.createUserWithEmailAndPassword(email, password);
    // this.sendVerificationEmail();
  }

 async getUid(){
    const user = await this.auth.currentUser;
    if (user === null){
      return null;
    }else{
      return user.uid;
    }
  }

  stateAuth(){
   return this.auth.authState;
  }

async getInfoUser(){
  const uid = await this.getUid();
  const path ='Usuarios/';
  this.firestoreService.getDoc<Usuario>(path, uid).subscribe(res =>{
    if (res !== undefined){
          this.datosUsuario = res;
    }
  });
}

async resetPassword(email: string): Promise<void>{
  try{
    return this.auth.sendPasswordResetEmail(email);
  }catch(error){
    window.alert(error.message);

  }

}

async sendVerificationEmail(): Promise<void>{
  try{
    return (await this.auth.currentUser).sendEmailVerification();
  }catch (error){
   // console.log('Error', error);
  }
}


isEmailVerified(usuario: Usuario): boolean{
  return usuario.emailVerified === true ? true : false;
}


async loginGoogle(){
  const provide = new firebase.default.auth.GoogleAuthProvider();
  const credential = await this.auth.signInWithPopup(provide);
  return this.updateData(credential.user);
  // window.location.reload();
// return this.auth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
}

async facebookLogin(){
//  const face = new firebase.default.auth.FacebookAuthProvider();
//   firebase.default.auth().signInWithPopup(face);
  await this.auth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider());
  // console.log(res.user)
 // const provide = new firebase.default.auth.FacebookAuthProvider();
  // const credential = await this.auth.signInWithPopup(res.user);
  // console.log(credential)
  // // return this.updateData(credential.user);
}

private updateData(user){
  const userRef: AngularFirestoreDocument<Usuario> = this.afs.doc(`Usuarios/${user.uid}`);
  const data ={
    uid: user.uid,
    nombre: user.displayName,
    email: user.email,
    password: '',
    emailVerified: user.emailVerified,
  };
  return userRef.set(data, {merge: true})
}

// async loginGoogle(): Promise<Usuario> {
//   try {
//     const  user  = await this.auth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
//   } catch (error) {
//     console.log('Error->', error);
//   }
// }

}