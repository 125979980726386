import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Tour, Usuario } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-chatvista',
  templateUrl: './chatvista.component.html',
  styleUrls: ['./chatvista.component.scss'],
})
export class ChatvistaComponent implements OnInit {
  tours: Tour []=[];
 
  chats:Chat[]=[];
  suscriber: Subscription;
  usuario: Usuario = {
    uid: '',
    nombre: '',
    email: '',
    password: '',
    // puntosUs: 100,
    // foto: '',
    // ubicacion: null,
    emailVerified: false,
  }
  uid ='';
  public nombreA:string;
  public nombreT:string;
  idT: string;
  public mensage: string;
  private idA: string;
  suscribeUserInfo: Subscription;
   chat: string;
   resp: string;
  fecha: Date;

  constructor( public modal: ModalController,
    private firestoreService: FirestoreService,
    private firebaseauthService: FirebaseauthService,
    private carritoService: CarritoService,
    public router: Router,
    public navparams: NavParams) {
      this.firebaseauthService.stateAuth().subscribe(res =>{
        if (res !== null) {
          this.uid = res.uid;
          
         this.getUserInfo(this.uid);
      }
      });
     }

  ngOnInit() {
    this.nombreA = this.navparams.get('nombreA');
    this.nombreT = this.navparams.get('nombre');
    this.idT = this.navparams.get('IdT');
    this.idA = this.navparams.get('IdA');
 //   console.log(this.nombreA, this.nombreT, this.idT, this.idA);
   // this.loadChats();
    this.load();
 //  this.getCC();
  }

  close(){
    this.modal.dismiss()
  }

  getUserInfo(uid:string){
    const path ='Usuarios/';
    this.suscribeUserInfo = this.firestoreService.getDoc<Usuario>(path, uid).subscribe(res =>{
    
        this.usuario = res;
    
    });
  }


  message(){
    const chat = this.mensage;
    const uid = this.uid;
  //  console.log(this.idA)
    const path =  'agencia/'+ this.idA + '/tour/' + this.idT + '/chat';
  //  console.log(path)
    const data: Chat = {
      autor: this.usuario.nombre,   
      chat,
      idA: this.idA,
      fecha: new Date(),
      id: this.firestoreService.getId(),
      uid,
      idT: this.idT,
      nombreT: this.nombreT,
      Respuesta: '',
      nombreA : this.nombreA,
    }
    if(uid.length){
    this.firestoreService.createDocChat(data, path, data.id).then( ()=>{
      this.mensage = '';
    });
  }else{
    this.router.navigate(['/perfil']);
    this.modal.dismiss()
  }
  }

  // getCC(){
  //   console.log('getCC')
  //   this.suscriber = this.firestoreService.getC(this.idT, '==', this.idT).subscribe(res=>{
  //       if(res.length){
  //         this.chats = res;
  //         console.log(this.chats);
  //       }
  //   });
  // } 

load(){
  const path = 'agencia/'+ this.idA + '/tour/' + this.idT + '/chat';
  this.suscriber =  this.firestoreService.getChat<Chat>(path).subscribe(res=>{
   // console.log(res);
    this.chats = res;
  });
}
    
  loadChats(){
    //  console.log(this.tour.id)
      let startAt = null;
      if(this.chats.length){
        startAt = this.chats[ this.chats.length - 1].fecha; 
      }

      this.firebaseauthService.stateAuth().subscribe(res =>{
        if (res !== null) {
          this.uid = res.uid;
          const path = 'agencia/'+ this.idA + '/tour/' + this.idT + '/chat';
          this.suscriber =  this.firestoreService.getChat<Chat>(path).subscribe( res =>{
              if(res.length) {
                res.forEach( comentario => {
                  const exist = this.chats.find( commentExist => {           
                     const dos =  commentExist.id === comentario.id;
                     return(dos); 
                  });
                  
                  if( exist === undefined && comentario.idA === this.idA){
            
                   this.chats.push(comentario);
                  }
                });
                  //this.comentarios = res;
                  
              }
          });
         
      }
      });


    }




}
 
interface Chat{
  autor: string;
  chat: string;
  fecha: any;
  id: string;
  uid: string;
  idT:string;
  nombreT:string;
  Respuesta: string,
  idA: string,
  nombreA: string,
}