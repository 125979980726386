import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Agencia } from 'src/app/models';
import { AgenciavistaComponent } from 'src/app/vista/agenciavista/agenciavista.component';


@Component({
  selector: 'app-agen-m',
  templateUrl: './agen-m.component.html',
  styleUrls: ['./agen-m.component.scss'],
})
export class AgenMComponent implements OnInit {

  @Input() agen: Agencia;

  constructor( public modal: ModalController) { }

  ngOnInit() {

  }
  openAgencia(agenc){
    this.modal.create({
      component: AgenciavistaComponent,
      componentProps :{
        nombre: agenc.nombre,
        descripcion: agenc.descripcion,
        foto: agenc.foto
      },
      cssClass:'transparent-modal'
    }).then ((modal)=> modal.present())
}


openAgIn(agenc){
  this.modal.create({
    component: AgenciavistaComponent,
    componentProps :{
      nombre: agenc.nombre,
      descripcion: agenc.description,
      foto: agenc.foto
    },
    cssClass:'transparent-modal'
  }).then ((modal)=> modal.present())
}
openAgPo(agenc){
  this.modal.create({
    component: AgenciavistaComponent,
    componentProps :{
      nombre: agenc.nombre,
      descripcion: agenc.descricao,
      foto: agenc.foto
    },
    cssClass:'transparent-modal'
  }).then ((modal)=> modal.present())
}

}
