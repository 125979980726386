import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { Pedido, Tour, Usuario, TourPedido } from '../models';
import { FirebaseauthService } from './firebaseauth.service';
import { FirestoreService } from './firestore.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { async } from '@firebase/util';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

 private pedido: Pedido;
 pedido$ = new Subject<Pedido>();
 path ='reserva/';
 uid = '';
 usuario: Usuario;
 cantidad : number;
 pedidos: Pedido[] =[];


carritoSuscriber: Subscription;
usuarioSusriber: Subscription;

  constructor( public firebaseauthService: FirebaseauthService,
               public firestoreService: FirestoreService,
               private firestore: AngularFirestore,
               public toastController: ToastController,
               public router: Router,
               public modal: ModalController,
               public angularFirestore: AngularFirestore
    ) {
    this.initCarrito();
    this.firebaseauthService.stateAuth().subscribe(res =>{
       if (res !== null) {
         this.uid = res.uid;
         this.loadCliente();
        }
     });
  }


  loadCarrito(){
    const path = 'Usuarios/'+ this.uid + '/' + 'reserva';
    if(this.carritoSuscriber){
      this.carritoSuscriber.unsubscribe();
    }
      this.firestoreService.getDoc<Pedido>(path, this.uid).subscribe(res =>{
          if(res){
            this.pedido = res;
            this.pedido$.next(this.pedido);
          }else{
            this.initCarrito();
          }
      });
  }

  initCarrito(){
    this.pedido = {
      uid: this.uid,
      cliente: this.usuario,
      productos:  [],
      precioTotal: null,
      precioReserva: null,
      estado: 'reserva',
      fecha: null,
      fechaRese: null,
      motivo: null,
      valoracion: null,
      pago: null,
    };
    this.pedido$.next(this.pedido);
  }



loadCliente(){
  const path ='Usuarios/';
  this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe(res =>{
      this.usuario = res;
      this.loadCarrito();
      this.loadPedido();
      this.getCarrito();
    //  this.usuarioSusriber.unsubscribe();
  });
}

  getCarrito(): Observable<Pedido> {
   // console.log(this.pedido);
    setTimeout(()=>{
      this.pedido$.next(this.pedido);
     // console.log(this.pedido);

    }, 100);
    return this.pedido$.asObservable();

  }

 async addTour(producto: Tour){
    if (this.uid.length){
     // console.log(this.pedido);
       const item = this.pedido.productos.find( tourPedido =>{
            return (tourPedido.producto.id === producto.id);
        });
        if(item !== undefined){
          item.cantidad ++;
        } else {
          const add: TourPedido = {
              cantidad: 1,
              producto,
          };
          await this.pedido.productos.push(add);
        }
    }else{
      this.router.navigate(['/perfil']);
      return;
    }
    this.pedido$.next(this.pedido);
    const path = 'Usuarios/'+ this.uid + '/' + this.path;
// console.log(this.pedido, path);
    this.firestoreService.createDoc(this.pedido, path, this.pedido.uid).then(() =>{
    });
    this.presentToast();
  }

  async cajearBe(){
    if (this.uid.length){
     // console.log(this.uid)
    }else{
      this.router.navigate(['/perfil']);
    }

  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Agregado Satisfactoriamente',
      duration: 2000
    });
     toast.present();
  }


  removeTour(producto: Tour){
    if (this.uid.length){
      let position = 0;
   //   console.log(this.pedido);
      const item = this.pedido.productos.find((tourPedido, index) =>{
          position = index;
           return (tourPedido.producto.id === producto.id);
       });

       if(item !== undefined){
         item.cantidad --;
         this.cantidad = item.cantidad;
         // console.log(item.cantidad);
       if(item.cantidad === 0 ){
        this.pedido.productos.splice(position, 1);
       }
   const path = 'Usuarios/'+ this.uid + '/' + this.path;
   this.firestoreService.createDoc(this.pedido, path, this.pedido.uid).then(() =>{
   });
  }

  }
}

getCantidad(){
  this.cantidad = 0;
  this.pedido.productos.forEach( producto =>{
   this.cantidad = producto.cantidad + this.cantidad;
});
}


  clearCarrito(){
    const path = 'Usuarios/'+ this.uid + '/' + 'reserva';
    this.firestoreService.deleteDoc(path, this.uid).then(()=>{
      this.initCarrito();
    });

  }


  async clearPorPagar(){
    const delet = await this.firestore.collection('Usuarios').doc(this.uid).collection('porpagar').doc(this.pedido.uid).delete();
    return(delet);
    // const path = 'Usuarios/'+ this.uid + '/' + 'porpagar';
    // this.firestoreService.deleteDoc(path, this.uid).then(()=>{
    //   this.initCarrito();
    // });

  }

 async clearCancel(){
    const path = 'Usuarios/'+ this.uid + '/' + 'cancelados';
   await this.firestoreService.deleteDoc(path, this.uid).then(()=>{
    });

  }

 async  BackCarrito(){
 // console.log(this.pedido.uid);
    const delet = await this.firestore.collection('Usuarios').doc(this.uid).collection('pedidos').doc(this.pedido.uid).delete();
    return delet;
  }

  async Badpay(){
    const delet = await this.firestore.collection('Usuarios').doc(this.uid).collection('pedidos').doc(this.pedido.uid).delete();
    return(delet)
  }

  async idPed(id:string){
    // console.log(this.uid);
   // console.log(id);


   // const delet =   await this.firestore.collection('Usuarios').doc(this.uid).collection('pedidos').doc(id).delete();
  // console.log(delet);

  }

  loadPedido(){
    const path = 'Usuarios/'+ this.uid + '/' + 'reserva';
      this.firestoreService.getDoc<Pedido>(path, this.uid).subscribe(res =>{
          if(res != null){
            this.pedido = res;
           // console.log(this.pedido);

            this.pedido$.next(this.pedido);
          }else{
            this.initCarrito();
          }
      });
  }

  loadPedidoo(){
    const path = 'Usuarios/'+ this.uid + '/' + 'pedidos';
      this.firestoreService.getDoc<Pedido>(path, this.uid).subscribe(res =>{
          if(res != null){
            this.pedido = res;
           // console.log(this.pedido);

            this.pedido$.next(this.pedido);
          }else{
            this.initCarrito();
          }
      });
  }


  loadId(){
    const path = 'Usuarios/'+ this.uid + '/' + 'pedidos';
      this.firestoreService.getDoc<Pedido>(path, this.uid).subscribe(res =>{
          if(res){
            this.pedido = res;
           // console.log(this.pedido);

            this.pedido$.next(this.pedido);
          }else{
            this.initCarrito();
          }
      });
  }


  // createDocChat(data: any, path: string, id:string){
  //   if (this.uid.length){
  //   const collection = this.angularFirestore.collection(path);
  //   return collection.doc(id).set(data);
  //   }else{
  //     this.router.navigate(['/perfil']);
  //     this.modal.dismiss()
  //     return;
  //   }
  // }


}