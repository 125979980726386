import { Component, OnInit, Input } from '@angular/core';
import { TourPedido } from 'src/app/models';
import { Pedido } from '../../models';
import { Subscription } from 'rxjs';
import { CarritoService } from '../../services/carrito.service';

@Component({
  selector: 'app-mistour',
  templateUrl: './mistour.component.html',
  styleUrls: ['./mistour.component.scss'],
})
export class MistourComponent implements OnInit {
  @Input() tourPedido: TourPedido;

  pedido: Pedido;
  carritoSuscriber: Subscription;
  total: number;
  reserva: string;
  cantidad: number;
  fecha: string;//Date;
  id: string;
  hotel: string;
  item: Subscription;
  constructor(public carritoService: CarritoService) {
    // this.initCarrito();
    this.loadPedido();
   }

  ngOnInit() {
  }
  loadPedido(){
    this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
      this.pedido = res;
      this.getTotal();
      this.getCantidad();
      this.getFecha();

    });
   }

   initCarrito(){
     this.pedido = {
       uid: '',
       cliente: null,
       productos:  [],
       precioTotal: null,
       precioReserva: null,
       estado: 'reserva',
       fecha: null,
       fechaRese: null,
       motivo: null,
       valoracion: null,
       pago: null,
     };

   }
   getTotal(){
    this.total = 0;
    const cant = this.tourPedido.cantidad;
    const precio = this.tourPedido.producto.valorA;
    this.total = (precio)* cant + this.total;
    const res = this.total *0.85;
    this.reserva = res.toFixed(2);
  }


  getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
  });
}
getFecha(){
  this.fecha = null;
  this.pedido.productos.forEach( producto =>{
    this.fecha = producto.producto.fecha;
    return this.fecha;
  });
 //  console.log(new Date(event.detail.value));

}

cancelar(tours: TourPedido){
  // console.log(tours.producto.Estado);

}


}
