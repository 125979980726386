import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tour } from 'src/app/models';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-star-m',
  templateUrl: './star-m.component.html',
  styleUrls: ['./star-m.component.scss'],
})
export class StarMComponent implements OnInit {

  @Input() tour: Tour;
  star0 = 0;
  star1 = 0;
  star2 = 0;
  star3 = 0;
  star4 = 0;
  total = 0;
  basado = 0;

  constructor(public modal: ModalController,
    private firestoreService: FirestoreService,) { }

  ngOnInit() {
   // console.log(this.tour);
   this.star0 = this.tour.star0;
   this.star1 = this.tour.star1;
   this.star2 = this.tour.star2;
   this.star3 = this.tour.star3;
   this.star4 = this.tour.star4;
   this.total = (this.star0 + this.star1 + this.star2 + this.star3 + this.star4)/5;
   this.basado = this.star0 + this.star1 + this.star2 + this.star3 + this.star4
  }

  closeModal(){
    this.modal.dismiss();
  }


}
