import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InformacionComponent } from './pages/informacion/informacion.component';
import { AgenciasComponent } from './pages/agencias/agencias.component';
import { TourComponent } from './pages/tour/tour.component';
import { BeneficiosComponent } from './pages/beneficios/beneficios.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { CarritoComponent } from './pages/carrito/carrito.component';
import { MistoursComponent } from './pages/mistours/mistours.component';
import { ResetpasswComponent } from './pages/resetpassw/resetpassw.component';
import { TerminosComponent } from './pages/terminos/terminos.component';
import { SendEmailComponent } from './pages/send-email/send-email.component';
import { PerfilUsmComponent } from './pages/perfil-usm/perfil-usm.component';
import { MistourComponent } from './componentes/mistour/mistour.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { SuccessComponent } from './pages/success/success.component';
import { CanceladosComponent } from './pages/cancelados/cancelados.component';
import { MistourscanceladosComponent } from './pages/mistourscancelados/mistourscancelados.component';
import { ExitotourscanceladoComponent } from './pages/exitotourscancelado/exitotourscancelado.component';
import { CancelpagoComponent } from './pages/cancelpago/cancelpago.component';
import { TabsComponent } from './pages/tabs/tabs.component';


const routes: Routes = [
 // { path:'', component: TabsComponent },
  { path:'tabs', component: TabsComponent},
  { path: '', redirectTo:'tabs/tour'}
  // { path:'perfil', component: PerfilComponent},
  // { path:'carrito', component: CarritoComponent},
  // { path:'mis-tours', component: MistoursComponent},
  // { path:'resetPsw', component: ResetpasswComponent},
  // { path:'termi', component: TerminosComponent},
  // { path:'sendEm', component: SendEmailComponent},
  // { path:'userP', component: PerfilUsmComponent},
  // { path:'check-out', component: CheckoutComponent},
  // { path:'pagoback', component: CancelpagoComponent},
  // { path:'success', component: SuccessComponent},
  // { path:'cancelados', component: CanceladosComponent},
  // { path:'miscancelados', component: MistourscanceladosComponent},
  // { path: 'exitocancelado', component: ExitotourscanceladoComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
