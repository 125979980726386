import { Component, Input, OnInit } from '@angular/core';
import { Pedido } from 'src/app/models';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { Subscription } from 'rxjs';
import { NavController, ViewWillEnter } from '@ionic/angular';
import { Tour, TourPedido } from '../../models';


@Component({
  selector: 'app-mistourscancelados',
  templateUrl: './mistourscancelados.component.html',
  styleUrls: ['./mistourscancelados.component.scss'],
})
export class MistourscanceladosComponent implements OnInit, ViewWillEnter {

@Input() tourPedido: TourPedido; 
  pedidos: Pedido[] = [];
  tou : Tour[];
  cancelado = true;
  canceladoSuscriber: Subscription;
  info: Tour [] = [];
  constructor(public firestoreService: FirestoreService,
    private router: Router,
    private navCtrl : NavController,
    public firebaseauthService: FirebaseauthService) {

     }
  
     ionViewWillEnter() { 
      this.getToursCancelados();
  }

  ngOnInit() {
    this.getToursCancelados();
    // this.navCtrl.
  }


  async getToursCancelados(){
    const uid = await this.firebaseauthService.getUid();
    const path = 'Usuarios/' + uid + '/cancelados/'; 
   // console.log(path);
    
 this.firestoreService.getCollection<Tour>(path).subscribe(res=>{
  // console.log(res);
  this.info= res;
 })

    // this.canceladoSuscriber = this.firestoreService.getCollectionQueryInfo<Tour>(path, 'Estado', '==', 'cancelado').subscribe(res=>{
    //   console.log(res);
      
    //     if(res.length){
    //       console.log(res);
          
    //       // this.info = res;
    //       // console.log(this.info);
          
    //     }
    // });
  }


  // async getToursCancelados(){
  //   const uid = await this.firebaseauthService.getUid();

  //   const path = 'Usuarios/' + uid + '/cancelados/';    
    
  //   let startAt = null;
  //   if(this.pedidos.length){
  //     startAt = this.pedidos[this.pedidos.length - 1 ].fecha
  //   }
  //  console.log(path);
  //  this.firestoreService.getCollection<Tour>(path).subscribe(res=>{
  //   console.log(res);
  //   res.forEach(doc=>{
  //     console.log(doc);
  //    // this.tou = doc;
  //   })
  //  })
  // // this.canceladoSuscriber = this.firestoreService.getCollectionQuery<Pedido>(path, 'estado', '==', 'reserva', startAt).subscribe(res=>{
  // //       if(res.length){
  // //         res.forEach(pedido=>{
  // //           const exist = this.pedidos.find( pediExist => {               
  // //             const rea =  pediExist.uid === pedido.uid;  
  // //           //  console.log(rea);
                          
  // //             return(rea);
              
  // //           });
  // //           if( exist === undefined){
  // //           this.pedidos.push(pedido);
  // //           }
  // //         });
  // //       }
  // // });
  

  // }



}
