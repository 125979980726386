import { Component } from '@angular/core';
import { Stripe } from '@capacitor-community/stripe';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  uid='';
  suscribeUserInfo: Subscription;
  countrycode:string="56";
  whatsappnumber:string="961130454";
  url:string="https://wa.me/"+this.countrycode+this.whatsappnumber+"?text= Hola necesito ayuda en,";
  constructor( private firebaseauth: FirebaseauthService,
                private platform: Platform,
                private router: Router,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar
               ) {
               this.firebaseauth.stateAuth().subscribe(res =>{
                if (res !== null){
                    this.uid = res.uid;
                }
               });

  this.initializeApp();
  }
  initializeApp(){
    this.platform.ready().then(()=>{
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

 async getId(){
    const uid = await this.firebaseauth.getUid();
  }

  async salir(){
   // console.log(this.uid)
    if (this.uid !== undefined){

      // await this.suscribeUserInfo.unsubscribe();
      this.firebaseauth.logout();
      this.router.navigate(['/home']).then(()=>{
       window.location.reload();
      });
     }else{
     // console.log('error');

     }
  }
}
