import { Component, Input, OnInit } from '@angular/core';
import { Bene } from 'src/app/models';
import { CarritoService } from '../../services/carrito.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BeneficiovistaComponent } from 'src/app/vista/beneficiovista/beneficiovista.component';

@Component({
  selector: 'app-bene-m',
  templateUrl: './bene-m.component.html',
  styleUrls: ['./bene-m.component.scss'],
})
export class BeneMComponent implements OnInit {
  @Input() bene: Bene;

uid =''
  constructor(
    private CarritoService: CarritoService,
    private Router: Router,
    public modal: ModalController,
  ) { }

  ngOnInit() {
    this.uid = this.CarritoService.uid;
  }
canje(bene){
  this.modal.create({
    component: BeneficiovistaComponent,
    componentProps :{
      nombre: bene.NombreC,
      descripcion: bene.Descripcion,
      foto: bene.Foto,
      NombreE: bene.NomEm
    },
    cssClass:'transparent-modal'
  }).then ((modal)=> modal.present())
 // this.CarritoService.cajearBe();
}
perfil(){
  this.Router.navigate(['../perfil']);
}
}