import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pedido, Usuario } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';
// import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
 // public user$: Observable<Usuario>;

  usuario: Usuario = {
    uid: '',
    nombre: '',
    email: '',
    password: '',
    // puntosUs: 100,
    // foto: '',
    // ubicacion: null,
    emailVerified: false
  }
newFile: any;
uid ='';
suscribeUserInfo: Subscription;
path='tour/';
pedido: Pedido;
ingresarEnable = false;
carritoSuscriber: Subscription;
cantidad: number;
Emver: boolean;

  constructor( public firebaseauthService: FirebaseauthService,
               public firestoreService: FirestoreService,
               public firestorageService: FirestorageService,
               private carritoService: CarritoService,
               private router: Router) {

              this.firebaseauthService.stateAuth().subscribe(res =>{
                  if (res !== null) {
                    this.uid = res.uid;
                    this.usuario.nombre = res.displayName;
                    this.usuario.email = res.email;
                    this.usuario.emailVerified = res.emailVerified;
                    this.Emver = res.emailVerified;

                   this.getUserInfo(this.uid, this.Emver );
                }else{
                  this.initCliente();

                }
                });
                this.loadPedido();
                }

  async ngOnInit() {
  // const uid= await  this.firebaseauthService.getUid();
  }


  initCliente(){
    this.uid = '';
    this.usuario ={
      uid: '',
      nombre: '',
      email: '',
      password: '',
      // puntosUs:100,
     // foto: '',
     // ubicacion: null,
      emailVerified: false
    }
  }

  // async newImageUpload(event: any){
  //   if(event.target.files && event.target.files[0]){
  //     this.newFile = event.target.files[0];
  //     const reader = new FileReader();
  //     reader.onload = ((image)=>{
  //       this.usuario.foto = image.target.result as string;
  //     });
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

  async  registrarse(){
    const credenciales= {
      email: this.usuario.email,
      password: this.usuario.password,
    };
   const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password);
   this.firebaseauthService.sendVerificationEmail();
   this.router.navigate(['/sendEm'])
   .catch(error =>{
     window.alert("La dirección de correo electrónico ya está en uso por otra cuenta");
   });
    const uid= await  this.firebaseauthService.getUid();
    this.usuario.uid = uid;
   this.guardarUsuario();
  }

  async guardarUsuario(){
    const path ='Usuarios/';
    const name = this.usuario.email;
    // if(this.newFile!== undefined){
    // const res = await this.firestorageService.uploadImage(this.newFile, path, name);
    // this.usuario.foto = res;
    // //this.guardarProducto();
    // }
   this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then(res =>{
   }).catch(error =>{
   });

  }

 async salir(){
    const uid = this.uid;
    if (uid !== undefined){

   await this.carritoSuscriber.unsubscribe();
     await this.suscribeUserInfo.unsubscribe();
     this.firebaseauthService.logout();
    }else{
    //  console.log('error');

    }

  }


  getUserInfo(uid:string, Emiaver: boolean){
    const path ='Usuarios/';
    this.suscribeUserInfo = this.firestoreService.getDoc<Usuario>(path, uid).subscribe(res =>{
      if (res !== undefined && Emiaver === true){
        this.usuario = res;
      }else if(Emiaver === false){
        // this.router.navigate(['/perfil'])
      }
    });
  }


async ingresar(){
  const credenciales= {
    email: this.usuario.email,
    password: this.usuario.password,
  };
   const user = await this.firebaseauthService.login(credenciales.email, credenciales.password);
  const email = user.user.emailVerified;
   this.redirectUser(email);
}

async  onGooglelogin(){
  try{
   await this.firebaseauthService.loginGoogle();
   this.router.navigate(['/userP']);
  }catch(error){
   // console.log(error);
  }
}
async facebookLogin(){
  try{
    await this.firebaseauthService.facebookLogin();
    this.router.navigate(['/userP']);
  }catch(error){
  // console.log(error);
  }
}

private redirectUser(isVerified: boolean): void {
  if (isVerified) {
    this.router.navigate(['/userP']);
  } else {
    this.router.navigate(['/sendEm']);
  }
}


ResetPass(){
  this.router.navigate(['/resetPsw']);
}

loadPedido(){
  this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
    this.pedido = res;
  });
 }

}
