import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import {  map } from 'rxjs/operators';
import { Tour, Bene } from '../models';
import { async } from '@firebase/util';
import { SwUpdate } from '@angular/service-worker';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';


@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  fires: Subscription;

  constructor(public angularFirestore: AngularFirestore,
  ) {

   }


  async createConfir(data: any, path: string, id: string){
   //  console.log(data, path);
     const dataa = await data;
     const collection = this.angularFirestore.collection(path).doc(id).collection('reservasOk')
   //  console.log(collection);
     //const prom =
     return collection.add(dataa);
    // return prom;


  }

  // updateUser(data: any, path: string, id: string){
  //   console.log(data, path)
  //   const updateDoc={
  //     puntosUs: data,
  //   }
  //   const collection = this.angularFirestore.collection(path);
  //   // return collection.doc(id).update(updateDoc);
  // }

  createDoc(data: any, path: string, id:string){
   // console.log(data);
    const collection = this.angularFirestore.collection(path);
    return collection.doc(id).set(data);
  }
  createDocChat(data: any, path: string, id:string){
    const collection = this.angularFirestore.collection(path);
    return collection.doc(id).set(data);
  }

  createDoccc(data: any, path: string){
    const collection = this.angularFirestore.collection(path);
    return collection.doc().set(data);
  }

getDoc<tipo>(path: string, id: string){
 const collection = this.angularFirestore.collection<tipo>(path);
  return collection.doc(id).valueChanges();
}

getDocca<tipo>(path: string){
  const collection = this.angularFirestore.collection<tipo>(path);
 // console.log(collection);
   return collection.doc().valueChanges();
 }

getPe<tipo>(path: string, id: string){
  const collection = this.angularFirestore.collection<tipo>(path);
   return collection.doc(id).valueChanges();
 }

getDocSub(path:string, id: string){
  const collection = this.angularFirestore.collection(path);
  return collection.doc(id).collection(path).valueChanges();
}

deleteDoc(path: string, id: string){
  const collection = this.angularFirestore.collection(path);
  return collection.doc(id).delete();
}

updateDoc(data: any, path: string, id: string){
  return this.angularFirestore.collection(path).doc(id).update(data);
}

getId(){
 return this.angularFirestore.createId()
}

getIdItem(path:string, id: string){
  const collection = this.angularFirestore.collection(path);
  return collection.doc(id)
 }

 getCollectioon(path: string){
  const collection = this.angularFirestore.collection(path);
  return collection.valueChanges();
}


getCollection<tipo>(path: string){
 // console.log(path)
  const collection = this.angularFirestore.collection<tipo>(path);
 // console.log(collection);
  return collection.valueChanges();
}


getCollectionn<tipo>(path: string, parametro: string, condicion: any, busqueda:string, startAt:any){
  if(startAt == null){
    startAt = new Date();
  }
 // console.log(startAt);

  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda).orderBy('fecha','desc').limit(1).startAfter(startAt));
  return collection.valueChanges();
}


getCollectionQuery<tipo>(path: string, parametro: string, condicion: any, busqueda:string, startAt: any){
  if(startAt == null){
    startAt = new Date();
  }
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda )
    .orderBy('fecha','desc')
    .limit(2)
    .startAfter(startAt));
  return collection.valueChanges();

}

getCollectionItem<tipo>(path: string, parametro: string,  condicion: any, busqueda:string, startAt: any){

  if(startAt == null){
    startAt = new Date();
  }

  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda ).orderBy('fecha','desc')
    .limit(5)
    .startAfter(startAt)
  );

 return collection.valueChanges();

}




getCollectionItemmm<tipo>(path: string,  parametro: string, condicion: any, busqueda:string,){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda ).orderBy('fecha','desc').limit(30));
  return collection.get();

}





getCollectionItemm<tipo>(path: string){
  // if(startAt == null){
  //   startAt = new Date();
  // }

  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.orderBy('fecha','desc')
    .limit(1)
   // .startAfter(startAt)
  );

  return collection.snapshotChanges().pipe(
    map(actions =>{
       actions.map(a => {
       // console.log(a);
        const data = a.payload.doc.data();
       // console.log(data);
        const docId = a.payload.doc.id;
      //  console.log(docId);

        this.updateCan(docId, path)
      // return collection.doc(docId).update({updateDoc});
      //  return {docId};
      });

    })

  )

}

UpdateSuccess(id: string, path: string){
  const UpdatePorp={
    estado:'realizadoo'
  }
  return this.angularFirestore.collection(path).doc(id).update(UpdatePorp);
}

updateCan(id: string, path: string){
  const updateDoc={
    estado: 'pagocancelado'
  }
 return this.angularFirestore.collection(path).doc(id).update(updateDoc);
}

getCollectionQueryInfo<tipo>(path: string, parametro: string, condicion: any, busqueda:string){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda )
    .orderBy('clasif','asc').limit(100).startAfter(0)
    );
  return collection.valueChanges();

}
getCollectionQueryInfoOo<tipo>(path: string, parametro: string, condicion: any, busqueda:string,){
   const collection = this.angularFirestore.collection<tipo>(path,
     ref => ref.where(parametro, condicion, busqueda).orderBy('fecha','desc')
     .limit(5)
     );

   return collection.valueChanges();

 }
 getCollectionQueryInfoO<tipo>(path: string, parametro: string, condicion: any, busqueda:string,){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda)
    );

  return collection.valueChanges();

}

getCollectionAll<tipo>(path, parametro: string, condicion: any, busqueda: string, startAt: any){
  if(startAt == null){
    startAt = new Date();
  }
  const collection = this.angularFirestore.collectionGroup<tipo>(path,
    ref => ref.where( parametro, condicion, busqueda)
    .orderBy('fecha','desc')
    .limit(5)
    .startAfter(startAt)
    );
  return collection.valueChanges();

}

getCollectionPag<tipo>(path: string, limit: number, startAt: any){
  if(startAt == null){
    startAt = new Date();
  }
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.orderBy('fecha','desc')
    .limit(limit)
    .startAfter(startAt)
    );
  return collection.valueChanges();

}

getChat<tipo>(path: string){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.orderBy('fecha','desc')
    );
  return collection.valueChanges();

}
 getC(parametro: string, condicion: any, busqueda: string){
  return this.angularFirestore.collectionGroup('chat', ref => ref.where(parametro, condicion, busqueda)).snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Chat;
     // console.log(data)
      data.id = doc.payload.doc.id;
      return data;
    })
  }))
}





getCarit<tipo>(path: string, parametro: string, condicion: any, busqueda:string){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref => ref.where(parametro, condicion, busqueda )
    );
  return collection.valueChanges();
}


getTour(){
  return this.angularFirestore.collectionGroup('tour').snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Tour;
      data.id = doc.payload.doc.id;
      return data;
    })
  }))
    }

    getStar(){
      return this.angularFirestore.collectionGroup('star').snapshotChanges().pipe(map(tour =>{
        return tour.map(doc =>{
          const data = doc.payload.doc.data() as star;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
        }


getTourReserva(){
  return this.angularFirestore.collectionGroup('reservasOk').snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Tour;
      data.id = doc.payload.doc.id;
      return data;
    })
  }))
    }

GetCancelados(){
  return this.angularFirestore.collectionGroup('tour').snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Tour;
      data.id = doc.payload.doc.id;
      return data;
    })
  }))
}

getTgroup(parametro: string, condicion: any, busqueda: string){
  return this.angularFirestore.collectionGroup('tour', ref => ref.where(parametro, condicion, busqueda)).snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Tour;
      data.id = doc.payload.doc.id;
      return data;
    })
  }))
}

getCugroup(parametro: string, condicion: any, busqueda: string){
  return this.angularFirestore.collectionGroup('cupones', ref => ref.where(parametro, condicion, busqueda)).snapshotChanges().pipe(map(tour =>{
    return tour.map(doc =>{
      const data = doc.payload.doc.data() as Bene;
     // console.log(data);
      return data;
    })
  }))
}

getSta<tipo>(path, parametro: string, condicion: any, busqueda: string ){
  const collection = this.angularFirestore.collection<tipo>(path,
    ref=> ref.where(parametro, condicion, busqueda)
    );
    // console.log(collection);

}

getGroup<tipo>(path,  parametro: string, condicion: any, busqueda: string){
  const collection = this.angularFirestore.collectionGroup<tipo>(path,
  ref => ref.where(parametro, condicion, busqueda)
  );
  return collection.valueChanges();
    }


    getUltimo<tipo>(path: string, parametro: string, condicion: any, busqueda:string, startAt: any){
      if(startAt == null){
        startAt = new Date();
      }
      const collection = this.angularFirestore.collection<tipo>(path,
        ref => ref.where(parametro, condicion, busqueda )
        .orderBy('fecha','desc')
        .limit(1)
        .startAfter(startAt));
      return collection.valueChanges();

    }

     getDoP<tipo>(path: string, id: string, uid: string){

      const collection = this.angularFirestore.collection<tipo>(path);
      const coll = collection.doc(id).get();
      coll.forEach(res=>{
       // console.log(res.ref);

      //  const id = res.ref.delete();
        const ref =  this.angularFirestore.collection('Usuarios').doc(uid).collection('pedidos');

        ref.doc(id).delete();


      })
      // const last = coll.docs[collection.doc.length - 1];
      // console.log(last);

     // return last;
      // return collection.doc(id).get();
     }


     getLast<tipo>(path: string, id: string, uid: string, startAt: any){
      if(startAt == null){
        startAt = new Date();
      }
      const collection = this.angularFirestore.collection<tipo>(path);
      const coll = collection.doc(id).get();
      // console.log(coll);

      coll.forEach(res=>{
       // console.log(res);

      //  const id = res.ref.delete();
        const ref =  this.angularFirestore.collection('Usuarios').doc(uid).collection('pedidos');
        //  console.log(ref.get());
      return  ref.doc(id).get();


      })
      // const last = coll.docs[collection.doc.length - 1];
      // console.log(last);

     // return last;
      // return collection.doc(id).get();
     }


     getDocc<tipo>(path: string, id: string, uid:string, startAt: any){
      if(startAt == null){
        startAt = new Date();
      }
      const collection = this.angularFirestore.collection<tipo>(path,
        ref => ref.orderBy('fecha','desc')
        .limitToLast(1)
        .endAt(startAt)
        );

        const dos = collection.doc(id).get();
         dos.subscribe(res=>{
          const idd = res.id;
          const updateDoc={
            Estado: 'pagocancelado'
          };
        return  this.angularFirestore.collection('Usuarios').doc(uid).collection('pedidos').doc(idd).update(updateDoc);
         })

       // console.log(dos);

  // return collection.doc(id).delete();

     }

     getCollectionREs(path: string, uid: string){
     //  console.log(path)
       const collection = this.angularFirestore.collection(path);
     const up =  collection.get()
       .subscribe((doc)=>{
        doc.forEach((res)=>{
          const idT = res.id;;
       //  console.log(res);
         const updateDoc={
          id: idT
        };
         this.angularFirestore.collection('Usuarios').doc(uid).collection('reservasOk').doc(idT).update(updateDoc);
        })
       });
       return collection.valueChanges();
     }


async updateCancel(uid:string, tours, idT, motivo0){
  // console.log(tours)
//   console.log("updateCancel");
// console.log(uid);
// console.log(tours);
const motivo = motivo0;
const cantidad = tours.cantidad;
// console.log(cantidad)
// const categoria = tours.categoria;
// const descricao = tours.descricao;
// const descripcionT = tours.descripcionT;
// const description = tours.description;
const fecha = tours.fecha;
// console.log(fecha);

const fotoT = tours.fotoT;
const whatsapp = tours.whatsapp;
const hotel = tours.hotel;
const id = tours.id;
const idA = tours.idA;

const nombreA = tours.nombreA;
const nombreT = tours.nombreT;
const ubicacion = tours.ubicacion;
const valorA = tours.valorA;

if(id === idT){

  const res = this.angularFirestore.firestore.collection('Usuarios').doc(uid).collection('cancelados');
// console.log(res);


  res.get().then((querySnapshot)=>{
    querySnapshot.forEach((doc)=>{
     const reee =  doc.data().producto;
    // console.log(reee);
    // reee.SwUpdate


        const reff = res.doc(id);
       // console.log(reff);

          reff.set({
            motivo: motivo,
            cantidad: cantidad,
            Estado: 'cancelado',
            fecha: fecha,
            fotoT: fotoT,
            whatsapp: whatsapp,
            hotel: hotel,
             id: id,
             idA: idA,
             nombreA: nombreA,
             nombreT: nombreT,
             ubicacion: ubicacion,
             valorA :valorA
          })
          reff.onSnapshot((querySnapshot)=>{
            // querySnapshot.ref
         //   console.log(querySnapshot.ref);

          })
    })
  });

  const ref = this.angularFirestore.firestore.collection('Usuarios').doc(uid).collection('reservasOk');

  ref.get().then((querySnapshot)=>{
    querySnapshot.forEach((doc)=>{
      // console.log(id);
      const idDa = doc.data().id;
      const reff = ref.doc(id);
     // console.log(reff);

   //  console.log(doc.data().id, '==>',id);
        reff.update({
          motivo: motivo,
          cantidad: cantidad,
          Estado: 'cancelado',
          fecha: fecha,
          fotoT: fotoT,
          whatsapp: whatsapp,
          hotel: hotel,
           id: id,
           idA: idA,
           nombreA: nombreA,
           nombreT: nombreT,
           ubicacion: ubicacion,
           valorA :valorA
        });


    });
  })

}else{
  // console.log(id, idT);
  // console.log('no coincide')
}

// const ped =  this.angularFirestore.collection('Usuarios').doc(uid).collection('pedidos');


// ped.get().forEach((doc)=>{
//   console.log(doc);
//   doc.forEach((a)=>{
//     console.log(a.data().productos);
//     const idd = a.id;
//     console.log(idd);

//     const b = a.data().productos;



//     // console.log(b);
//     // console.log(fecha);
//     // console.log(ped.doc(b));

//     ped.doc(idd).update({
//      motivo : motivo,
//         'productos.cantidad':cantidad,
//         'productos.producto.Estado':'cancelado',
//         'productos.producto.categoria':categoria,
//         'productos.producto.descricao':descricao,
//         'productos.producto.descripcionT': descripcionT,
//         'productos.producto.description': description,
//         'productos.producto.fecha': fecha,
//         'productos.producto.fotoT': fotoT,
//         'productos.producto.whatsapp': whatsapp,
//         'productos.producto.hotel':hotel,
//         'productos.producto.id': id,
//         'productos.producto.idA': idA,
//         'productos.producto.idCom': idCom,
//         'productos.producto.nombreA': nombreA,
//         'productos.producto.nombreT': nombreT,
//         'productos.producto.ubicacion': ubicacion,
//         'productos.producto.valorA': valorA
//     });

//   });
// });

























//     // b.forEach(element => {
//     //   // console.log(element.producto.Estado);

//     //   const idPR = element.producto.id;
//     //   if(idPR == id){

//     //     console.log(element.producto.id);

//     //   ped.doc(idd).update({
//     //     'productos.motivo': motivo,
//     //     'productos.cantidad':cantidad,
//     //     'productos.producto.Estado':'cancelado',
//     //     'productos.producto.categoria':categoria,
//     //     'productos.producto.descricao':descricao,
//     //     'productos.producto.descripcionT': descripcionT,
//     //     'productos.producto.description': description,
//     //     'productos.producto.fecha': fecha,
//     //     'productos.producto.fotoT': fotoT,
//     //     'productos.producto.whatsapp': whatsapp,
//     //     'productos.producto.hotel':hotel,
//     //     'productos.producto.id': id,
//     //     'productos.producto.idA': idA,
//     //     'productos.producto.idCom': idCom,
//     //     'productos.producto.nombreA': nombreA,
//     //     'productos.producto.nombreT': nombreT,
//     //     'productos.producto.ubicacion': ubicacion,
//     //     'productos.producto.valorA': valorA
//     //   });
//     //   }

//     // });

//   })

// })

   // console.log(ped.doc());
// ped.get().then((querySnapshot)=>{
//   querySnapshot.forEach((doc)=>{
//     const idDo = doc.id;

//      const data = doc.data();

//     console.log(ped.doc(idDo));

//    const aa = ped.doc(idDo).get();


//     const refer = ped.doc(idDo);

//     // refer.update({
//     //   'productos.producto.Estado':'cancelado'
//     // })
//   })
// })

  // ped.get().then((querySnapshot)=>{
  //   querySnapshot.query.get().then((doc)=>{
  //       doc.docs.forEach((res)=>{
  //        // console.log(res.data().uid);
  //         const Id = res.data().uid;
  //         console.log(Id);
  //         const prod = res.data().productos;

  //           ped.snapshotChanges().pipe(map(

  //          prod.map(a=>{
  //           const idP = a.producto.id;
  //           console.log(id, idP);
  //         const refff = ped.doc(Id);
  //           // if(id == idP){

  //           //   refff.update({
  //           //     'productos.producto.Estado':'cancelado'
  //           //   })
  //           // }
  //          })
  //         // const re = res.data().productos;


  //       }
  //       )
  //   })
  //   // querySnapshot.docs.forEach((doc)=>{
  //   //   //console.log(doc.data().productos);
  //   //  const refs = doc.data();
  //   //   console.log(refs.productos);


  //   // })

  //   // querySnapshot.forEach((doc)=>{
  //   //    console.log(doc);
  //   //   const redoc = doc.data().productos;




  //   // });
  // })
  // this.angularFirestore.firestore.collectionGroup('cancelados').onSnapshot((querySnapshot)=>{
  //   querySnapshot.forEach(ddoc=>{
  //     console.log(ddoc.id);
  //     const reff = ddoc.id;

  //   var Estadoo = ddoc.data().uid;
  //   console.log(Estadoo);

  //   // Estadoo.update({
  //   //   Estado: 'cancelado'
  //   // })
  //       //console.log(Estado);

  //   })
  // })
  // return this.angularFirestore.collectionGroup('cancelados').snapshotChanges().pipe(map(un=>{
  //     console.log(un);
  //    return un.map(a=>{
  //       console.log(a.payload);

  //     })
  // }))

  // console.log(ref);
  //   ref.pipe(map(a=>{
  //     a.docChanges().forEach(u=>{
  //       console.log(u);

  //     })

  //   }))
  // ref.forEach(a=>{
  //   console.log(a);
  //   a.forEach(b=>{

  //     console.log(b.data.bind);

  //   })
//   // });
//  ref.subscribe(res=>{

//   console.log(res);
//   res.docs.forEach(doc=>{
//     // console.log(doc);
//     // doc
//     // const can = doc.data();



//   })


//  })

}




}
interface Chat{
  autor: string;
  chat: string;
  fecha: any;
  id: string;
  uid: string;
  idT:string;
  nombreT:string;
  Respuesta: string,
  idA: string,
  nombreA: string,
}
interface star{
  autor: string;
  id: string;
}