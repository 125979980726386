import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Agencia, Pedido } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Component({
  selector: 'app-agencias',
  templateUrl: './agencias.component.html',
  styleUrls: ['./agencias.component.scss'],
})
export class AgenciasComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  private path = 'agencia/';

 agencia: Agencia [] = [];
 pedido: Pedido;
 cantidad: number;
 carritoSuscriber: Subscription; 
 uid='';

  constructor(public firestoreService: FirestoreService,
              private carritoService: CarritoService,
              private firebaseauth: FirebaseauthService,
              public modal: ModalController) {
                this.firebaseauth.stateAuth().subscribe(res =>{
                  if (res !== null){
                      this.uid = res.uid;    
                  }
                 });
    this.loadAgencia(); 
   }

  ngOnInit() {
    this.loadPedido();
  }

  loadAgencia(){
    this.firestoreService.getCollection<Agencia>(this.path).subscribe(res =>{
      this.agencia = res;

    })
  }


  loadPedido(){
    this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
      this.pedido = res;      
      this.getCantidad(); 
           
    });
   }

   getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
  });
}

 
loadData(event, direction: 'top' | 'bottom') {
  setTimeout(()=>{
    event.target.complete();
    if(direction == 'top' && this.agencia.length === 1000){
      event.target.disabled = true;
    }else if(direction == 'bottom' && this.agencia.length === 1000){
      event.target.disabled = true;
    }
  }, 1000);
}

toggleInfiniteScroll() {
  this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
}

}
