import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Tour } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-cancelarvista',
  templateUrl: './cancelarvista.component.html',
  styleUrls: ['./cancelarvista.component.scss'],
})
export class CancelarvistaComponent implements OnInit {
  tours: Tour []=[];
  public foto: string;
  public motivo:string;
  public nombre:string;
  idT: string;

  constructor(public firestoreService: FirestoreService,
    private carritoSer: CarritoService,
    private router: Router,
    public modal: ModalController,
    public navparams: NavParams,
    public firebaseauthService: FirebaseauthService) {


     }

  ngOnInit() {
    this.nombre = this.navparams.get('nombre');
    this.foto = this.navparams.get('foto');
    this.idT = this.navparams.get('IdT')
   // console.log(this.nombre, this.foto, this.idT);
    this.firestoreService.getTourReserva().subscribe(tour =>{

      this.tours = tour;


    });
  }


 async cancelar(){
   // console.log(this.motivo, this.tours)
  const uid = await this.firebaseauthService.getUid();
  // console.log(this.tours);
   this.tours.forEach(doc=>{
    // console.log(doc)
   // console.log(this.idT,'==', doc.id);
    if(this.idT === doc.id){
     // console.log(this.idT,'==', doc.id);
      // console.log(this.idT,'==', doc.IdTour);
      // console.log(this.nombre, doc);
       this.firestoreService.updateCancel(uid, doc, this.idT, this.motivo);
    }


   });

  // console.log(uid);
//  await this.firestoreService.updateCancel(uid, tours);
 // window.alert('Cancelado')

 // this.router.navigate(['exitocancelado']);


  // const id =tours.producto.id;
  // // console.log('cancelar()=>', tours.motivo);
  // console.log(tours.producto.Estado);
  // tours.producto.update({
  // Estado :'cancelado'
  // })

  //   const path='Usuarios/' + uid + '/cancelados/';
  //   console.log(path);

  //   const updateDoc={
  //     Estado: 'cancelado',
  //     motivo: tours.motivo,
  //   };
    // if(tours.motivo.length){
    //  //  await  this.firestoreService.updateDoc(updateDoc, path, id).then(()=>{
    //   //  console.log('Actualizado');

    //     // this.carritoSer.clearCancel();
    //  // });
    // }

// const patht='Usuarios/' + uid + '/pedidos/';

this.modal.dismiss()
 }
 close(){
  this.modal.dismiss()
}


}
