import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { from } from 'rxjs';
import { SwiperOptions } from 'swiper';

import SwiperCore, { Zoom } from'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Zoom])

@Component({
  selector: 'app-img-info',
  templateUrl: './img-info.component.html',
  styleUrls: ['./img-info.component.scss'],
})
export class ImgInfoComponent implements OnInit {

  public nombre : string;
  public descripcion : string;
  public foto: string;
  public ubicacion: string;


  @ViewChild('swiper') swiper: SwiperComponent;
  
  config: SwiperOptions = {
    zoom: true
  };


  constructor(private navParams: NavParams,
              private modal: ModalController) { }

  ngOnInit() {
    this.nombre = this.navParams.get('nombre')
    this.ubicacion = this.navParams.get('ubicacion')
    this.descripcion = this.navParams.get('descripcion')
    this.foto = this.navParams.get('foto')
  }

  zoom(zoomIn){
    const zoom = this.swiper.swiperRef.zoom;
    zoomIn ? zoom.in() : zoom.out();

  }

close(){
  this.modal.dismiss();
}
}
