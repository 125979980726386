import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-agenciavista',
  templateUrl: './agenciavista.component.html',
  styleUrls: ['./agenciavista.component.scss'],
})
export class AgenciavistaComponent implements OnInit {
  
  public nombre : string;
  public descripcion : string;
  public foto: string;

  constructor( public navparams: NavParams,
                public modal: ModalController) { }

  ngOnInit() {
    this.nombre = this.navparams.get('nombre')
    this.descripcion = this.navparams.get('descripcion')
    this.foto = this.navparams.get('foto')
   }
 
   closeAg(){
     this.modal.dismiss()
   }

}
