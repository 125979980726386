import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tourvista',
  templateUrl: './tourvista.component.html',
  styleUrls: ['./tourvista.component.scss'],
})
export class TourvistaComponent implements OnInit {
  public nombre : string; 
  public descripcion : string;
  public valor : string;
  public foto : string;
  public nombreA : string;

  constructor( private navparams: NavParams,
                private modal: ModalController) { }

  ngOnInit() {   
  this.nombre = this.navparams.get('nombre')
  this.descripcion = this.navparams.get('descripcion')
  this.valor = this.navparams.get('valor')
  this.foto = this.navparams.get('foto')
  this.nombreA = this.navparams.get('nombreA')
}
closeT(){
  this.modal.dismiss()
}


}
 