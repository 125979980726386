import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InfoT } from 'src/app/models';
import { ImgInfoComponent } from '../img-info/img-info.component';
import { ImgGalComponent } from '../img-gal/img-gal.component';



@Component({
  selector: 'app-info-m',
  templateUrl: './info-m.component.html',
  styleUrls: ['./info-m.component.scss'],
})
export class InfoMComponent implements OnInit {
  @Input() info: InfoT;


  constructor( public modal: ModalController) { }

  ngOnInit() {

  }
  openInfoT(info){
    this.modal.create({
      component: ImgInfoComponent,
      componentProps :{
        nombre: info.nombre,
        descripcion: info.descripcion,
        ubicacion: info.ubicacion,
        categoria: info.categoria,
        foto: info.foto
      },
      cssClass:'transparent-modal'
    }).then ((modal)=> modal.present())


  }

  openIn(info){
    this.modal.create({
      component: ImgInfoComponent,
      componentProps :{
        nombre: info.nombre,
        descripcion: info.description,
        ubicacion: info.ubicacion,
        categoria: info.categoria,
        foto: info.foto
      },
      cssClass:'transparent-modal'
    }).then ((modal)=> modal.present())

  }

  openPo(info){
    this.modal.create({
      component: ImgInfoComponent,
      componentProps :{
        nombre: info.nombre,
        descripcion: info.descricao,
        ubicacion: info.ubicacion,
        categoria: info.categoria,
        foto: info.foto
      },
      cssClass:'transparent-modal'
    }).then ((modal)=> modal.present())
  }


  openFoto(info){
    this.modal.create({
      component: ImgGalComponent,
      componentProps:{
        nom: info.nombre,
        foto: info.Gal
      },
      cssClass:'transparent-modal'
    }).then((modal)=> modal.present())
  }
  
}
