import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { from } from 'rxjs';
import { Tour } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { ComentariosComponent } from'../comentarios/comentarios.component';
import { TourvistaComponent } from 'src/app/vista/tourvista/tourvista.component';
import { TourGalComponent } from '../tour-gal/tour-gal.component';
import { StarMComponent } from '../star-m/star-m.component';
import { ChatvistaComponent } from 'src/app/vista/chatvista/chatvista.component';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { Share } from'@capacitor/share'; 
// import { SocialSharing } from '@ionic-native/social-sharing/ngx';


@Component({
  selector: 'app-tours-m',
  templateUrl: './tours-m.component.html',
  styleUrls: ['./tours-m.component.scss'],
})
export class ToursMComponent implements OnInit {
  @Input() tour: Tour;
  uid='';
  nombreT:string;
  nombreA:string;
  countrycode:string="56";
  whatsappnumber:string="961130454";
  url:string="https://wa.me/"+this.countrycode+this.whatsappnumber+"?text= Hola necesito ayuda en,";


  constructor( private carritoService: CarritoService,
              // private socialSharing: SocialSharing, 
              private firebaseauth: FirebaseauthService,           
               private modalController: ModalController) {
            
                this.firebaseauth.stateAuth().subscribe(res =>{
                  if (res !== null){
                      this.uid = res.uid;    
                  }
                 });
                }

  ngOnInit() {

  }
 whatsapp(tour){
   this.nombreA = tour.nombreA;
    this.nombreT = tour.nombreT;
// console.log(this.nombreA, this.nombreT);

  }
  addCarrito(){    
      this.carritoService.addTour(this.tour);
  }

chat(tour){
   // this.tour.idCom
  this.modalController.create({
    component: ChatvistaComponent,
    componentProps :{
      nombre: tour.nombreT,
      IdT: tour.IdTour,
      IdA: tour.idCom,
      nombreA: tour.nombreA
    },
    cssClass:'transparent-modal'
  }).then ((modal)=> modal.present())

}

  async openModal(){
      const modal = await this.modalController.create({
        component: ComentariosComponent,
        componentProps: {tour: this.tour}        
      });
      return await modal.present();
  } 

  async star(){
    const modal = await this.modalController.create({
      component: StarMComponent,
      componentProps: {tour: this.tour}        
    });
    return await modal.present();
} 


  


  
  openTour(tour){
    this.modalController.create({
      component: TourvistaComponent,
      componentProps :{
        nombre: tour.nombreT,
        descripcion: tour.descripcionT,
        valor: tour.valotT,
        foto: tour.fotoT,
        nombreA: tour.nombreA
      },
      cssClass:'transparent-modal'
    }).then ((modal)=> modal.present())
    }

    openIn(tour){
      this.modalController.create({
        component: TourvistaComponent,
        componentProps :{
          nombre: tour.nombreT,
          descripcion: tour.description,
          valor: tour.valotT,
          foto: tour.fotoT,
          nombreA: tour.nombreA
        },
        cssClass:'transparent-modal'
      }).then ((modal)=> modal.present())
      
    }

    openPo(tour){
      this.modalController.create({
        component: TourvistaComponent,
        componentProps :{
          nombre: tour.nombreT,
          descripcion: tour.descricao,
          valor: tour.valotT,
          foto: tour.fotoT,
          nombreA: tour.nombreA
        },
        cssClass:'transparent-modal'
      }).then ((modal)=> modal.present())

    }
    openFoto(tour){
      this.modalController.create({
        component: TourGalComponent,
        componentProps:{
          foto: tour.Gal,
          nom: tour.nombreT
        },
        cssClass:'transparent-modal'
      }).then((modal)=> modal.present())
    }

// async Shared(){
//   const tittle = this.tour.nombreT;
//   const text = this.tour.descripcionT;
//   const url = this.tour.fotoT;
      
//   await Share.share({
//     title: tittle,
//     text: text,
//     url: url,
//   });
// }

// ShareFacebook(){
//   const url = this.tour.categoria;
//   const image = this.tour.fotoT;
//   const message = this.tour.nombreT;
//   this.socialSharing.shareViaFacebookWithPasteMessageHint(message, image, null /* url */, 'Copia Pega!')
// }

// SendEmail(){
//   this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com'])
// }

// SendTwitter(){
//   const url = this.tour.categoria;
//   const image = this.tour.fotoT;
//   const message = this.tour.nombreT
//   this.socialSharing.shareViaTwitter(message, image,)
// }

// SendInstagram(){
//   // const url = this.tour.categoria;
//   const image = this.tour.fotoT;
//   const message = this.tour.nombreT;
//   this.socialSharing.shareViaInstagram(message, image).then((res)=>{
//     console.log(res);
    
//   })
// }


//     ShareWhatsapp(){
//       const url = this.tour.categoria;
//       const image = this.tour.fotoT;
//       const message = this.tour.nombreT
//       this.socialSharing.shareViaWhatsApp(url, image, message)
//     }

}
