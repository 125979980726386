import { Component, OnInit, OnDestroy, Pipe } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pedido } from 'src/app/models';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Component({
  selector: 'app-cancelpago',
  templateUrl: './cancelpago.component.html',
  styleUrls: ['./cancelpago.component.scss'],
})
export class CancelpagoComponent implements OnInit, OnDestroy {

  carritoSuscriber: Subscription;
  id : string;
  idT:string;
  pedidos: Pedido[] =[];
  total: number;
  cantidad: number;
  pedido: Pedido;
  uid = '';
  suscriber: Subscription;
  cueno : string;
  constructor(private carrito: CarritoService,
              private firestore: FirestoreService,
              public firebaseauthService: FirebaseauthService,
              private router: Router) {
this.firebaseauthService.stateAuth().subscribe(res=>{
  if (res !== null){
    this.uid = res.uid;
   // this.getIdTour(this.uid);
  //  let startAt = null;
  //  if(this.pedido.fecha){
  //    startAt = this.pedido[ this.pedido.fecha - 1];
  //  }
   const path = 'Usuarios/' + this.uid + '/pedidos/';

    this.suscriber = this.firestore.getCollectionItemm<Pedido>(path).subscribe(res =>{
      return res;
    // console.log(res);

    })

    // console.log(dos);


//    this.firestore.getCollectionItemm<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res=>{
//     if(res.length){
// console.log(res);
//     const finds  =   res.find(ers=>{
//       console.log(ers);

//          // const  ext = ers.uid == this.pedido.uid
//         })
//         console.log(finds);



// //       res.forEach(pedido =>{
// //         const exist = this.pedidos.find(resEx =>{
// //           const reEx = resEx.uid == pedido.uid;
// //           return (reEx);

// //         });
// //         if( exist === undefined){
// //           const updateDoc={
// //             estado: 'pago cancelado'
// //           };
// //           console.log(pedido.uid);

// //           // this.firestore.updateDoc(updateDoc, path, pedido.uid).then(()=>{
// //           //   console.log('actualizado');

// //           // })
// //         }

// //   // Object.values(pedido);
// //   // console.log(pedido);

// //   //  this.id = pedido.uid;
// //   //  console.log(this.id);

// //   // this.Update(this.id);

// //  // this.firestore.updateDoc(updateDoc, path, this.id)


// // })
// }
// });
  }
});



     this.router.navigate(['check-out']);
   //  window.location.reload();
  }

   ngOnInit() {
 //   console.log(this.uid);

  }
  ngOnDestroy(): void{
    if(this.suscriber){
      this.suscriber.unsubscribe();
    }
  }



//   async cancelar(tours: Pedido){
//     console.log(tours.estado);

//     const path='Usuarios/' + tours.cliente.uid + '/pedidos/';
//     const updateDoc={
//       estado: 'pago cancelado'
//     };
//     const id =tours.uid;
//      // await  this.firestore.updateDoc(updateDoc, path, id)

//   }



//  getIdTour(uid:string){
//   let startAt = null;
//   if(this.pedidos.length){
//     startAt = this.pedidos[ this.pedidos.length - 1];
//   }
//   const path = 'Usuarios/' + uid + '/pedidos/';
//   // this.firestore.getCollectionItem<Pedido>(path, 'estado', '==', 'reserva')

//   this.suscriber =  this.firestore.getCollectionItem<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res=>{
//     if(res.length){

//        res.forEach(pedido =>{
//   this.pedidos = Object.values(pedido);

//    this.id = pedido.uid;
//   const updateDoc={
//     estado: 'pagocancelado'
//   };
//  // this.firestore.updateDoc(updateDoc, path, this.id)


// })
// }
// });

// }

// async Update(id: string){
//   // console.log(id);
//   // console.log(this.uid);
//   const updateDoc={
//     estado: 'pago cancelado',
//   };
//   const path = 'Usuarios/' + this.uid + '/pedidos/';
// //  console.log(path);

//   await  this.firestore.updateDoc(updateDoc, path, id)

// }

// loadComentarios(){
//   let startAt = null;
//   if(this.comentarios.length){
//     startAt = this.comentarios[ this.comentarios.length - 1].fecha;
//   }
//  const path = 'agencia/'+ this.tour.idCom + '/tour/' + this.tour.id + '/comentarios';
//  this.suscriber =  this.firestoreService.getCollectionPag<Comentario>(path, 2, startAt).subscribe( res =>{
//      if(res.length) {
//        res.forEach( comentario => {
//          const exist = this.comentarios.find( commentExist => {
//             const dos =  commentExist.id === comentario.id;
//             return(dos);
//          });

//          if( exist === undefined){
//           this.comentarios.push(comentario);
//          }
//        });
//          //this.comentarios = res;

//      }
//  });
// }


// get(uid: string){
//   const path = 'Usuarios/' + uid + '/pedidos/';
//   this.firestore.getDoP<Pedido>(path, id)
// }

}
