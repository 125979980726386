import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Bene, Beneficio } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-beneficiovista',
  templateUrl: './beneficiovista.component.html',
  styleUrls: ['./beneficiovista.component.scss'],
})
export class BeneficiovistaComponent implements OnInit {
  @Input() bene: Bene;

  public nombre : string;
  public descripcion : string;
  public foto: string;
  public nomE: string;
uid='';
  constructor(public navparams: NavParams,
                private firestorage: FirestoreService,
                private router: Router,
                public firebaseauth: FirebaseauthService,
                public modal: ModalController) {
                  this.firebaseauth.stateAuth().subscribe(res =>{
                    if (res !== null){
                        this.uid = res.uid;
                    }
                   });
                }

  ngOnInit() {
    this.nombre = this.navparams.get('nombre');
    this.descripcion = this.navparams.get('descripcion');
    this.foto = this.navparams.get('foto');
    this.nomE = this.navparams.get('NombreE');
  }

  closeAg(){
    this.modal.dismiss()
  }
  Canje(nomb: string, foto: string, nomEE: string){
// console.log(nomEE);
    const more = 3;
    const dat = new Date();

    const result = dat.setDate(dat.getDate() + more);
    const ffecha = result.valueOf()
    // console.log(ffecha);
    const data :Beneficio={
       nombre : nomb,
       foto : foto,
       fecha : new Date(result),
       estado: 'nuevo',
       ffecha: ffecha,
       NomEm : nomEE

    }
    const path = '/Usuarios/'+ this.uid +'/misBeneficios/'
   // console.log(data, this.uid, path);

   this.firestorage.createDoccc(data, path);
   window.alert('Agregado a mis Beneficios');

  this.closeAg();
  }


}
