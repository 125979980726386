import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarritoService } from '../../services/carrito.service';
import { Subscription } from 'rxjs';
import { TourPedido, Pedido, Usuario } from 'src/app/models';
import { FirestoreService } from '../../services/firestore.service';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { element } from 'protractor';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
@Input() tourPedido: TourPedido;

  carritoSuscriber: Subscription;
  pedido: Pedido;
  cantidad: number;
  total: number;
  reserva: number;
  usuario: Usuario;
  fecha: any;
  pedidos: Pedido[] =[];
  uid = '';
  id : string;
  data =[];
  Ptos: number;
 // obje: any;
 // path ='';

   constructor(private route: Router,
              private carrS: CarritoService,
              private firestore: FirestoreService,
              private firAuth: FirebaseauthService,) {

                this.firAuth.stateAuth().subscribe(res =>{
                  if (res !== null) {
                    this.uid =  res.uid;
                    // this.loadPun(this.uid);
                    this.loadCliente();
                   }else{
                    this.route.navigate(['/userP'])
                   }

                });


               }
  ngOnInit() {
    this.getLast();

  }

  //  loadPun(id: string){
  //   let startAt = null;
  //   if(this.pedidos.length){
  //     startAt = this.pedidos[ this.pedidos.length - 1].fecha;
  //   };
  //   const uid = id;
  // //  console.log(uid);
  //   const path = 'Usuarios/' + uid + '/reservasOk/';
  //   this.firestore.getCollection<TourPedido>(path).subscribe(res=>{
  //     res.forEach((doc)=>{
  //      const cantidad = doc.cantidad;
  //       // console.log(doc.cantidad);
  //       // console.log(this.Ptos)
  //       const ptos = cantidad * 50 + this.Ptos;
  //    //   console.log(ptos)
  //       const pathh = '/Usuarios'
  //       this.firestore.updateUser(ptos, pathh, uid);

  //     //  doc.productos.forEach((res)=>{
  //     //   console.log(res.cantidad);
  //     //  });
  //     });

  //   });
  //   // this.firestore.getCollectionItem<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res =>{
  //   //   console.log(res);
  //   //   if(res.length){
  //   //     console.log(res);
  //   //     res.forEach(pedido =>{
  //   //    this.pedidos = Object.values(pedido);
  //   //    const id = pedido.uid;
  //   //    console.log(id);

  //   //  });
  //   //  }else{
  //   //   console.log('Error...');
  //   //  }
  //   // })
  // }

 loadCliente(){
 // console.log(this.uid);

    const path ='Usuarios/';
    this.firestore.getDoc<Usuario>(path, this.uid).subscribe(res =>{
        this.usuario = res;
      //  this.Ptos = this.usuario.puntosUs;

       // this.loadCarrito();
        this.loadPedido();
    });
  }


revisar(){
  this.route.navigate(['/mis-tours']);
  this.carrS.clearCarrito();
//  this.carrS.clearPorPagar();
}

home(){
  this.route.navigate(['/home']);
  this.carrS.clearCarrito();
//  this.carrS.clearPorPagar();

}
perfil(){
  this.route.navigate(['/userP']);
  this.carrS.clearCarrito();
//  this.carrS.clearPorPagar();
}

initCarrito(){
  this.pedido = {
    uid: '',
    cliente: null,
    productos:  [],
    precioTotal: null,
    precioReserva: null,
    estado: 'reserva',
    fecha: null,
    fechaRese: null,
    motivo: null,
    valoracion: null,
    pago: null,
  };

}

async getLast(){
  let startAt = null;
  if(this.pedidos.length){
    startAt = this.pedidos[ this.pedidos.length - 1].fecha;
  }
  const uid = await this.firAuth.getUid();
  const path = 'Usuarios/' + uid + '/pedidos/';
  this.firestore.getCollectionItem<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res =>{
    if(res.length){
     // console.log(res);
      res.forEach(pedido =>{
     this.pedidos = Object.values(pedido);
  //   console.log(this.pedidos);
     const id = pedido.uid;
   // console.log(id);
    // this.firestore.getDoP<Pedido>(path, id, uid);

     // this.carrito.idPed(id);

       // const exist = this.pedidos.find( pedidoExist =>{
       //   const rep = pedidoExist === pedido;
       //   return(rep);
       // });
       // if(exist === undefined){

       // }

   });
   };
  })
}



async loadPedido(){
  // const uid = await this.firAuth.getUid();
  // console.log(uid);
  const path =  'Usuarios/' + this.uid  + '/pedidos/' ;
  const pathh = 'Usuarios/';
  let data = [];
  var obje : any;
  // console.log(path, pathh);
  let startAt = null;

  if(this.pedidos.length){
    // console.log(this.pedido);
    startAt = this.pedido[this.pedidos.length - 1 ].fecha;
    // console.log(startAt);
  }



   this.firestore.getCollectionn<Pedido>(path, 'estado', '==', 'reserva', startAt).subscribe(res =>{
// console.log(res);
      res.forEach((doc)=>{
    //  console.log(doc.uid);
      const idPP = doc.uid;
      doc.productos.forEach((dat)=>{

         // console.log(dat.producto.IdTour);
          const uid = doc.cliente.uid;
          const total = doc.precioTotal;
          const reserva = doc.precioReserva;
          // const path = '/Usuarios'

        //  this.Ptos = dat.cantidad * 50 + this.usuario.puntosUs;
        // this.firestore.updateUser(this.Ptos, path, uid);
         // console.log(this.Ptos, uid);

           this.id = dat.producto.id;
          // console.log(this.id);
            data =[{
            nombre: doc.cliente.nombre,
            email :  doc.cliente.email,
            uid: doc.cliente.uid,
            Estado  : dat.producto.Estado,
            cantidad : dat.cantidad,
            fecha : dat.producto.fecha,
            fotoT : dat.producto.fotoT,
            whatsapp : dat.producto.whatsapp,
            hotel : dat.producto.hotel,
            id : dat.producto.id,
            idA : dat.producto.idA,
            nombreA : dat.producto.nombreA,
            nombreT : dat.producto.nombreT,
            ubicacion : dat.producto.ubicacion,
            valorA : dat.producto.valorA,
            idCom: dat.producto.idCom,
            IdTour: dat.producto.IdTour,
            clasificacion: dat.producto.clasificacion,
            pagoTotal : total,
            pagoReserva: reserva,
         }];

      //  console.log(data);

         data.forEach((obj)=>{
          obje = obj;
          // console.log(obje);

         })
       //  if(this.id === this)
           // console.log(obje);



         this.firestore.createConfir(obje, pathh, this.uid);
         this.firestore.UpdateSuccess(idPP,path);


         return;
        //  data.forEach((obj)=>{
        //   console.log(obj.fecha);

        //  obje = obj;
        //  // return obje;
        // });
        // const findElement = (data) => {
        //   for (let i = 0; i < data.length; i++) {
        //     const element = data[i];
        //     if (element.fecha === dat.producto.fecha) {
        //       return element;
        //     }
        //   }
        //   return -1;
        // }




        // console.log(obje.fecha);
        // if(obje.fecha == dat.producto.fecha){
        //     console.log('Fecha ok');
        //     this.firestore.createConfir(obje, pathh);

        // }else if(obje.fotoT !== dat.producto.fotoT ){
        //   console.log('Existe');
        //   return obje;
        // }else{
        //  console.log('malo');

        // }
        });

      //  console.log(data, obje);
      //  this.firestore.createConfir(obje, pathh);
      //   return data;
    return;
  });
    return;
 //  console.log(data, obje);
// return data;
});
// return data;
}

 getTotal(){
  this.total = 0;
  this.pedido.productos.forEach( producto =>{
   this.total = (producto.producto.valorA) * producto.cantidad + this.total;
   this.reserva = this.total;

  });

}


getCantidad(){
  this.cantidad = 0;
  this.pedido.productos.forEach( producto =>{
    this.cantidad = producto.cantidad + this.cantidad;
//   console.log(this.cantidad);
});
}

getFecha(){
this.fecha = null;
this.pedido.productos.forEach(producto =>{
  this.fecha = producto.producto.fecha;
  // console.log(this.fecha);


});
}

// async confirm(){
//   const uid = await this.firAuth.getUid();
//   const path = 'Usuarios/' + uid + '/reservasOk/'


//  this.pedido.productos.forEach(res=>{
//   const idd = res.producto.id;
//   console.log(idd);
//   console.log(res);


//   console.log(res.producto);

//   console.log(res.producto.fecha);
//   console.log(this.fecha);


//   const data =[{
//      nombre: this.pedido.cliente.nombre,
//      email : this.pedido.cliente.email,
//      uid: this.pedido.cliente.uid,
//      Estado  : res.producto.Estado,
//      cantidad : res.cantidad,
//      fecha : res.producto.fecha,
//      fotoT : res.producto.fotoT,
//      whatsapp : res.producto.whatsapp,
//      hotel : res.producto.hotel,
//      id : res.producto.id,
//      idA : res.producto.idA,
//      nombreA : res.producto.nombreA,
//      nombreT : res.producto.nombreT,
//      ubicacion : res.producto.ubicacion,
//      valorA : res.producto.valorA,
//      idCom: res.producto.idCom,


//   }]
//   data.forEach((obj)=>{
//     console.log(obj);
//     this.firestore.createConfir(obj, path, idd);

//   })

//  // this.firestore.createConfir(data, path, id);


//  });

// //   const idT = id;
// //   console.log(idT);
// //   const nomCli = this.pedido.cliente.nombre;
// //   const email = this.pedido.cliente.email;

// //   this.pedido.productos.forEach(res=>{
// //      console.log(res.producto.Estado);
// //      this.idd = res.producto.id;
// //      console.log(this.idd, idT);

// // if(this.idd === idT){
// //   console.log(this.idd, idT);
// //    // this.textBoton = 'ok';
// // }
// //   });

// }


// async getLastPe(){
//   let startAt = null;
//   if(this.pedidos.length){
//     startAt = this.pedidos[ this.pedidos.length - 1];
//   }

//    console.log(this.uid);

//    const path = 'Usuarios/' + this.uid + '/pedidos/';
//    console.log(path);

//   this.firestore.getCollectionItem<Pedido>(path,'estado', '==', 'reserva', startAt).subscribe(res =>{
//     if(res.length){

//       res.forEach(pedido =>{
//      this.pedidos = Object.values(pedido);
//      const id = pedido.uid;
//      console.log(pedido);

//         pedido.productos.forEach((res)=>{
//           console.log(res.producto.fecha);

//         });
//     // console.log(id);
//    //  this.firestore.getDoP<Pedido>(path, id, uid);

//      // this.carrito.idPed(id);

//        // const exist = this.pedidos.find( pedidoExist =>{
//        //   const rep = pedidoExist === pedido;
//        //   return(rep);
//        // });
//        // if(exist === undefined){

//        // }

//    });
//    };
//   });
// }

// loadCliente(){
//   const path ='Usuarios/';
//   this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe(res =>{
//       this.usuario = res;
//       this.loadCarrito();
//       this.loadPedido();

//   });
// }
}
