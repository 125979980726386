import { Component, OnInit } from '@angular/core';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resetpassw',
  templateUrl: './resetpassw.component.html',
  styleUrls: ['./resetpassw.component.scss'],
  providers:[FirebaseauthService],
})
export class ResetpasswComponent implements OnInit {
  userEmail = new FormControl('');

  constructor(private authService: FirebaseauthService,
              private router: Router) { }

  ngOnInit() {}

async Reset(){
  try{
    const email = this.userEmail.value;
    await this.authService.resetPassword(email);
    window.alert('Email enviado');  
    this.router.navigate(['/perfil']);
  } catch(error){
    window.alert(error.message);
  }
  
}

}
 