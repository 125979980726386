import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgenMComponent } from './agen-m/agen-m.component';
import { BeneMComponent } from './bene-m/bene-m.component';
import { ComentariosComponent } from './comentarios/comentarios.component';
import { ImgInfoComponent } from './img-info/img-info.component';
import { InfoMComponent } from './info-m/info-m.component';
import { ItemcarritoComponent } from './itemcarrito/itemcarrito.component';
import { ToursMComponent } from './tours-m/tours-m.component';
import { SwiperModule } from 'swiper/angular';
import { BrowserModule } from '@angular/platform-browser';
import { MistourComponent } from './mistour/mistour.component';
import { ImgGalComponent } from './img-gal/img-gal.component';
import { TourGalComponent } from './tour-gal/tour-gal.component';
import { StarMComponent } from './star-m/star-m.component';



@NgModule({
  declarations: [
    AgenMComponent, 
    BeneMComponent, 
    ComentariosComponent, 
    ImgInfoComponent, 
    InfoMComponent,
    ItemcarritoComponent,
    ToursMComponent,
    MistourComponent,
    ImgGalComponent,
    StarMComponent,
    TourGalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    FormsModule
  ], exports:[
    AgenMComponent,
    BeneMComponent,
    ComentariosComponent,
    ImgInfoComponent,
    InfoMComponent,
    ItemcarritoComponent,
    ToursMComponent,
    MistourComponent,
    ImgGalComponent,
    TourGalComponent
  ]
})
export class ComponentesModule { }
