import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent }from './tabs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { InformacionComponent } from '../informacion/informacion.component';
import { AgenciasComponent } from '../agencias/agencias.component';
import { TourComponent } from '../tour/tour.component';
import { BeneficiosComponent } from '../beneficios/beneficios.component';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ComponentesModule } from 'src/app/componentes/componentes.module';
import { PerfilComponent } from '../perfil/perfil.component';
import { CarritoComponent } from '../carrito/carrito.component';
import { MistoursComponent } from '../mistours/mistours.component';
import { ResetpasswComponent } from '../resetpassw/resetpassw.component';
import { TerminosComponent } from '../terminos/terminos.component';
import { SendEmailComponent } from '../send-email/send-email.component';
import { PerfilUsmComponent } from '../perfil-usm/perfil-usm.component';
import { CheckoutComponent } from '../checkout/checkout.component';
import { CancelpagoComponent } from '../cancelpago/cancelpago.component';
import { SuccessComponent } from '../success/success.component';
import { CanceladosComponent } from '../cancelados/cancelados.component';
import { MistourscanceladosComponent } from '../mistourscancelados/mistourscancelados.component';
import { ExitotourscanceladoComponent } from '../exitotourscancelado/exitotourscancelado.component';
import { MisbeneficiosComponent } from '../misbeneficios/misbeneficios.component';

const routes: Routes =[

{ path:'', component:  TabsComponent, children :[
 // { path: '', component: InformacionComponent},
  // { path:'', loadChildren: ()=> import('../informacion/informacion.component').then((m)=> m.InformacionComponent)},
  { path: 'home', component: InformacionComponent},
  { path: 'agencias', component: AgenciasComponent },
  { path: 'tour', component: TourComponent },
  { path: 'bene', component: BeneficiosComponent },
  { path:'perfil', component: PerfilComponent},
  { path:'carrito', component: CarritoComponent},
  { path:'mis-tours', component: MistoursComponent},
  { path:'resetPsw', component: ResetpasswComponent},
  { path:'termi', component: TerminosComponent},
  { path:'sendEm', component: SendEmailComponent},
  { path:'userP', component: PerfilUsmComponent},
  { path:'check-out', component: CheckoutComponent},
  { path:'pagoback', component: CancelpagoComponent},
  { path:'success', component: SuccessComponent},
  { path:'cancelados', component: CanceladosComponent},
  { path:'miscancelados', component: MistourscanceladosComponent},
  { path: 'exitocancelado', component: ExitotourscanceladoComponent},
  { path:'misBene', component: MisbeneficiosComponent},
  { path:'', redirectTo:'tour', pathMatch:'full'},
  { path:'', redirectTo:'tour'},
  { path: '**', redirectTo: 'tour', pathMatch: 'full'}
]

}
];

@NgModule({
  declarations: [ TabsComponent ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ComponentesModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})
export class TabsModule { }
