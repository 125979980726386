import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TourvistaComponent } from './tourvista/tourvista.component';
import { InfovistaComponent } from './infovista/infovista.component';
import { AgenciavistaComponent } from './agenciavista/agenciavista.component';
import { BrowserModule } from '@angular/platform-browser';
import { BeneficiovistaComponent } from './beneficiovista/beneficiovista.component';
import { ClasificacionvistaComponent } from './clasificacionvista/clasificacionvista.component';
import { ComponentesModule } from '../componentes/componentes.module';
import { CancelarvistaComponent } from './cancelarvista/cancelarvista.component';
import { ChatvistaComponent } from './chatvista/chatvista.component';



@NgModule({
  declarations: [ 
    TourvistaComponent,
    InfovistaComponent,
    BeneficiovistaComponent,
    ClasificacionvistaComponent,
    CancelarvistaComponent,
    ChatvistaComponent,
    AgenciavistaComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ComponentesModule,
    ReactiveFormsModule
  ]
})
export class VistaModule { }
