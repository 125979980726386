import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-perfil-usm',
  templateUrl: './perfil-usm.component.html',
  styleUrls: ['./perfil-usm.component.scss'],
})
export class PerfilUsmComponent implements OnInit {

  usuario: Usuario = {
    uid: '',
    nombre: '',
    email: '',
    password: '',
    // puntosUs: 100,
    // foto: '',
    // ubicacion: null,
    emailVerified: false,
  }
  uid ='';
  suscribeUserInfo: Subscription;
  emV :boolean;

  constructor( private firebaseauthService:FirebaseauthService,
                private router: Router,
              private firestoreService: FirestoreService ) {

    this.firebaseauthService.stateAuth().subscribe(res =>{
      if (res !== null) {
        this.uid = res.uid;
        this.emV = res.emailVerified;

       this.getUserInfo(this.uid, this.emV);
    }else{
      this.initCliente();

    }
    });
   }

  async ngOnInit() {

    const uid= await  this.firebaseauthService.getUid();


  }

  async salir(){
    const uid = this.uid;
    if (uid !== undefined){

     await this.suscribeUserInfo.unsubscribe();
     this.firebaseauthService.logout();
     this.router.navigate(['/home']).then(()=>{
      window.location.reload();
     });
    }else{
    //  console.log('error');

    }

  }

  getUserInfo(uid:string, Emiaver: boolean){
    const path ='Usuarios/';
    this.suscribeUserInfo = this.firestoreService.getDoc<Usuario>(path, uid).subscribe(res =>{
      if (res !== undefined && Emiaver === true){
        this.usuario = res;
      }else if(Emiaver === false){
        // this.router.navigate(['/perfil'])
      }
    });
  }

  initCliente(){
    this.uid = '';
    this.usuario ={
      uid: '',
      nombre: '',
      email: '',
      password: '',
      // puntosUs: 100,
      // foto: '',
      // ubicacion: null,
      emailVerified: false
    }
  }
  BackLogin(){
    this.router.navigate(['/perfil']);
  }

  tienda(){
    this.router.navigate(['/tour'])
  }
  Bene(){
    this.router.navigate(['/bene'])
  }

  mistours(){
    this.router.navigate(['/mis-tours'])
  }
  bene(){
    this.router.navigate(['/misBene'])
  }

  infor(){
    this.router.navigate(['/home'])
  }
}
