import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Bene, Pedido } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';


@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss'],
})
export class BeneficiosComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;


  private path = 'beneficios/';

  benefi: Bene[]= [];
  pedido: Pedido;
  cantidad: number;
  carritoSuscriber: Subscription;
  bene: Subscription;
  uid='';
  constructor(  public firestoreService: FirestoreService,
                private firebaseauth: FirebaseauthService,
                private ngZone: NgZone,
                 private carritoService: CarritoService) {
                this.firebaseauth.stateAuth().subscribe(res =>{
                  if (res !== null){
                      this.uid = res.uid;
                  }
                 });
  }

  ngOnInit() {
    this.getComida();
    this.loadPedido();
  }

  loadBene(){
    this.ngZone.run(()=>{
      this.firestoreService.getCollection<Bene>(this.path).subscribe(res =>{
        this.benefi = res;
      });
    });

  }

  loadPedido(){
    this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
      this.pedido = res;
      this.getCantidad();

    });
   }

   getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
  });
}

changeSegment(event:any){


  const opc = event.detail.value;
  if(opc === 'gastronomia'){
    this.getComida();
  }
  if(opc === 'Rski'){
    this.getRski();
  }
  if(opc === 'deporte'){
    this.getDeporte();
    }
  if(opc === 'belleza'){
  this.getBelleza();
  }
  if(opc === 'ropa'){
    this.getRopa();
    }
  if(opc === 'entretencion'){
    this.getEntretencion();
    }
    if(opc === 'mas'){
      this.getMas();
      }

}

getComida(){
  this.bene = this.firestoreService.getCugroup('categoria', '==', 'Comida').subscribe(res=>{
      if(res.length){
        this.benefi = res;

      }
  });
}

getDeporte(){
  this.bene = this.firestoreService.getCugroup('categoria', '==', 'Deportes').subscribe(res=>{
      if(res.length){
        this.benefi = res;
      // console.log(this.benefi);

      }
  });
}
getBelleza(){
  this.bene = this.firestoreService.getCugroup('categoria', '==', 'Belleza').subscribe(res=>{
      if(res.length){
        this.benefi = res;

      }
  });
}


getRopa(){
  this.bene = this.firestoreService.getCugroup( 'categoria', '==', 'Ropa').subscribe(res=>{
      if(res.length){
        this.benefi = res;
       // //  console.log(this.bene);
      }
  });
}

getEntretencion(){
  this.bene = this.firestoreService.getCugroup('categoria', '==', 'Entretencion').subscribe(res=>{
      if(res.length){
        this.benefi = res;
       //  console.log(this.bene);
      }
  });
}

getMas(){
  this.bene = this.firestoreService.getCugroup('categoria', '==', 'Otros').subscribe(res=>{
      if(res.length){
        this.benefi = res;
       //  console.log(this.bene);
      }
  });
}

getRski(){
  this.bene = this.firestoreService.getCugroup( 'categoria', '==', 'Rnieve').subscribe(res=>{
      if(res.length){
        this.benefi = res;
       // //  console.log(this.bene);
      }
  });
}


loadData(event, direction: 'top' | 'bottom') {
  setTimeout(()=>{
    event.target.complete();
    // this.infiniteScroll.disabled = true;
    if(direction === 'top' && this.benefi.length === 1000){
      event.target.disabled = true;
      // this.infiniteScroll.disabled = true;

    }else if(direction === 'bottom' && this.benefi.length === 1000){
      event.target.disabled = true;
      // this.infiniteScroll.disabled = true;
    }

  }, 1000);



}


}


