import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { from } from 'rxjs';
import { SwiperOptions } from 'swiper';

import SwiperCore, { Zoom } from'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Zoom])
@Component({
  selector: 'app-tour-gal',
  templateUrl: './tour-gal.component.html',
  styleUrls: ['./tour-gal.component.scss'],
})
export class TourGalComponent implements OnInit {
  @ViewChild('swiper') swiper: SwiperComponent;
  
  public foto: [];
  public nom: string;
 
 
  sliderOps={
    zoom: true,
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween:10,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false
    // }
  }
 
  constructor(private navParams: NavParams,
    private modal: ModalController) { }

  ngOnInit() {
    this.foto = this.navParams.get('foto');
    this.nom = this.navParams.get('nom');
  }

  zoom(zoomIn){
    const zoom = this.swiper.swiperRef.zoom;
    zoomIn ? zoom.in() : zoom.out();

  }

  close(){
    this.modal.dismiss();
  }
}
 