import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-infovista',
  templateUrl: './infovista.component.html',
  styleUrls: ['./infovista.component.scss'],
})
export class InfovistaComponent implements OnInit {

  public nombre : string;
  public descripcion : string;
  public foto: string;
  public ubicacion: string;

  constructor( private navparams: NavParams, private modal: ModalController) { }

  ngOnInit() {
    this.nombre = this.navparams.get('nombre')
    this.ubicacion = this.navparams.get('ubicacion')
    this.descripcion = this.navparams.get('descripcion')
    this.foto = this.navparams.get('foto')
  }
  closeD(){
    this.modal.dismiss()
  }
  } 



