import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TourPedido } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { Pedido } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-itemcarrito',
  templateUrl: './itemcarrito.component.html',
  styleUrls: ['./itemcarrito.component.scss'],
})
export class ItemcarritoComponent implements OnInit {
  @ViewChild('input') myInput ;
  fecha: string; // Date = new Date();
  @Input() tourPedido: TourPedido;
  @Input() botones = true;

  pedido: Pedido;
  carritoSuscriber: Subscription;
  total: number;
  reserva: string;
  cantidad: number;
  id: string;
  hotel: string;
  item: Subscription;

  constructor(public carritoService: CarritoService) {
   // this.initCarrito();
    this.loadPedido();


  //  this.getTotal();
  }

  ionViewLoaded() {

    setTimeout(() => {
      this.myInput.setFocus();
    },150);

 }

 enable_days=(dateIsoString:string)=>{
  const day = new Date(dateIsoString);
  // console.log(day)
  const today = new Date();
 const todate = today.setDate(today.getDate());
// console.log(todate);
const fech = new Date(todate);
 // console.log(fech)
  // console.log(day,'===', today);
  if( day >= today ) {
    return true;
  }else{
    return false;
}
}

 numericOnly(event): boolean {
  let pattern = /^([0-9])$/;
  let result = pattern.test(event.key);
  return result;
}

 cambio(event){
 const estado = event.detail.value;
 if(estado == 'Reserva'){
//  console.log(this.pedido);
 }else if(estado == 'Total'){
  // console.log(estado);
 }else if(estado ==''){
 // console.log('debe ingresar estado');
 }
//  console.log(event.detail.value);

 }
  ngOnInit() {}

  addCarrito(){
      this.carritoService.addTour(this.tourPedido.producto);
  }
  rem(){
    this.carritoService.removeTour(this.tourPedido.producto);
  }



  loadPedido(){
    this.carritoSuscriber = this.carritoService.getCarrito().subscribe(res =>{
      this.pedido = res;
      this.getTotal();
      this.getCantidad();
      this.getFecha();
    });
   }

   initCarrito(){
     this.pedido = {
       uid: '',
       cliente: null,
       productos:  [],
       precioTotal: null,
       precioReserva: null,
       estado: 'reserva',
       fecha: null,
       fechaRese: null,
       motivo: null,
       valoracion: null,
       pago: null,
     };

   }
  getTotal(){
    this.total = 0;
    const cant = this.tourPedido.cantidad;
    const precio = this.tourPedido.producto.valorA;
    this.total = (precio)* cant + this.total;
    const res = this.total *0.85;
    this.reserva = res.toFixed(2);

  }

  getCantidad(){
    this.cantidad = 0;
    this.pedido.productos.forEach( producto =>{
      this.cantidad = producto.cantidad + this.cantidad;
  });
}
getFecha(){
  this.fecha = null;
  this.pedido.productos.forEach( producto =>{
    this.fecha = producto.producto.fecha;
    return this.fecha;
  });
 //  console.log(new Date(event.detail.value));

}
}
