import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-exitotourscancelado',
  templateUrl: './exitotourscancelado.component.html',
  styleUrls: ['./exitotourscancelado.component.scss'],
})
export class ExitotourscanceladoComponent implements OnInit {
uid='';
  constructor(private router: Router,
              private FirStore: FirestoreService,
              private Afauth: FirebaseauthService) { }

  async ngOnInit() {
    this.uid = await this.Afauth.getUid()
   // console.log(this.uid);
     
  }

  mistourRes(){
this.router.navigate(['/mis-tours']).then(()=>{
  window.location.reload();
});
  }

  Perfil(){
    this.router.navigate(['/userP']).then(()=>{
    //  window.location.reload();
    });
      }
    
  contacto(){
    window.location.href = "mailto:contacto@gosvay.com?subject=Reembolso Reservacion Tour ";
  }

  salir(){}
}
