import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Pedido, TourPedido } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Router } from '@angular/router';
import { IonSegment, ModalController } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { Tour } from '../../models';
import { ClasificacionvistaComponent } from '../../vista/clasificacionvista/clasificacionvista.component';
import { CancelarvistaComponent } from 'src/app/vista/cancelarvista/cancelarvista.component';
@Component({
  selector: 'app-mistours',
  templateUrl: './mistours.component.html',
  styleUrls: ['./mistours.component.scss'],
})
export class MistoursComponent implements OnInit, OnDestroy {
   @Input() tourPedido: TourPedido;

  nuevoSuscriber: Subscription;
  realizadoSuscriber: Subscription;
  uid ='';
  pedidos: Pedido[] = [];
  pedidosRealizados: Pedido[] = [];
  pedidoe: Pedido;
  nuevos = true;
  tour: Tour [];
  condition: number = 0;
  list: any[] = new Array(5);
  tourResId : string;

  constructor(public firestoreService: FirestoreService,
              private router: Router,
              public modal: ModalController,
              public firebaseauthService: FirebaseauthService) { }

  ngOnInit() {
   this.getToursNuevos();
   this.GetTnuevos();
    // this.getTour();
    }

    clasificar(tou){
    // console.log(tou)
      this.modal.create({
        component: ClasificacionvistaComponent,
        componentProps :{
          nombre: tou.nombreT,
          Id: tou.IdTour,
          foto: tou.fotoT,
          idA: tou.idCom,
        },
        cssClass:'transparent-modal'
      }).then ((modal)=> modal.present())
    }


  ngOnDestroy(){
    if (this.nuevoSuscriber){
      this.nuevoSuscriber.unsubscribe();
    }
    if (this.realizadoSuscriber){
      this.realizadoSuscriber.unsubscribe();
    }
  }



  changeSegment(event: any){
   // console.log(event.detail.value);

    const opc = event.detail.value;
    if(opc === 'realizado'){
      this.nuevos = false;
      this.GetTnuevos();
    }
    if(opc === 'reserva'){
      this.nuevos = true;
      this.GetTnuevos();
    }
  }

async getTour(){
  const uid = await this.firebaseauthService.getUid();
  const path = 'Usuarios/' + uid + '/pedidos/';
//  console.log(uid, path);
  let startAt = null;
if(this.pedidos.length){
  startAt = this.pedidos[this.pedidos.length - 1 ].fecha
}
this.nuevoSuscriber = this.firestoreService.getCollectionQuery<Pedido>(path, 'estado', '==', 'reserva', startAt).subscribe(res=>{
      if(res.length){
        res.forEach(pedido =>{
          pedido.productos.forEach(res=>{
            if(res.producto.Estado === 'reserva'){
            //  console.log(res);

              this.tourPedido = res;

            }


          })
          // pedido.productos.forEach(doc =>{
          //   console.log(doc.producto.id);
          //   console.log(doc.producto);
          //   const EstadoDoc = doc.producto.Estado;
          //   // this.pedidoe = doc.producto;

          // });

          const exist = this.pedidos.find( pediExist => {
          //  console.log(pedido.uid);


            const rea =  pediExist.uid === pedido.uid;

            return(rea);
          });
           if( exist === undefined){
          this.pedidos.push(pedido);
          }
        });
      }
});

}


async getToursNuevos(){
const uid = await this.firebaseauthService.getUid();
const path = 'Usuarios/' + uid + '/pedidos/';
// console.log(this.pedidoe)
let startAt = null;
if(this.pedidos.length){
  startAt = this.pedidos[this.pedidos.length - 1 ].fecha
}
this.nuevoSuscriber = this.firestoreService.getCollectionQuery<Pedido>(path, 'estado', '==', 'reserva', startAt).subscribe(res=>{
      if(res.length){
        res.forEach(pedido =>{

          this.pedidoe = pedido;
        //  console.log(this.pedidoe)


          const exist = this.pedidos.find( pediExist => {

            const rea =  pediExist.uid === pedido.uid;

            return(rea);
          });
           if( exist === undefined){
          this.pedidos.push(pedido);
          }
        });
      }
});
}

async getToursRealizados(){
  const uid = await this.firebaseauthService.getUid();
  const path = 'Usuarios/' + uid + '/pedidos/';
  let startAt = null;
  if(this.pedidosRealizados.length){
    startAt = this.pedidosRealizados[this.pedidosRealizados.length - 1 ].fecha
  }
this.realizadoSuscriber = this.firestoreService.getCollectionQuery<Pedido>(path, 'estado', '==', 'realizado', startAt).subscribe(res=>{
      if(res.length){
        res.forEach(pedido=>{
          const exist = this.pedidos.find( pediExist => {
            const rea =  pediExist.uid === pedido.uid;
            return(rea);
          });
          if( exist === undefined){
          this.pedidosRealizados.push(pedido);
          }
        });
      }
});
}

async GetTnuevos(){
  const uid = await this.firebaseauthService.getUid();
  const path = 'Usuarios/' + uid + '/reservasOk/';
  //  this.firestoreService.getCollectionREs(path, uid).subscribe(dos=>{
  //  // console.log(dos);
  //   // dos.forEach((uno)=>{
  //   //   console.log(uno);
  //   // });
  //  });


  this.nuevoSuscriber =  this.firestoreService.getCollection<Tour>(path).subscribe(res=>{
     res.forEach((doc)=>{
      this.tourResId = doc.id;
     // console.log(doc);
     })
      this.tour = res;
    })

}


cargarMas(){
  if(this.nuevos){
    this.GetTnuevos();
    // this.getTour();
  }else{
    this.getToursRealizados();
  }
}

async cancelar(tours: Tour){
  const uid = await this.firebaseauthService.getUid();
  const path = 'Usuarios/' + uid + '/reservasOk/';
   this.firestoreService.getCollectionREs(path, uid).subscribe(dos=>{

   });

 // console.log( this.tour)
  const tour= tours;
 //  console.log(tour.id);
  this.modal.create({
    component: CancelarvistaComponent,
    componentProps :{
      nombre: tours.nombreT,
      foto: tours.fotoT,
      IdT: tour.id,

    },
    cssClass:'transparent-modal'
  }).then ((modal)=> modal.present())

  // const uid = await this.firebaseauthService.getUid();
// console.log(tour.id);
   const pathh = 'Usuarios/' + uid + '/cancelados/';
  await this.firestoreService.createDoc(tour, pathh, tour.id).then(()=>{
   // this.router.navigate(['cancelados'])

  });
}



}
